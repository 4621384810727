import PropTypes from "prop-types";
// @mui
import { Link, TableCell, TableRow, Typography } from "@mui/material";
// import check and cross icons from material icons
import { useSnackbar } from "src/components/snackbar";
import Label from "src/components/label/Label";
import { useState } from "react";
import { processSubscription } from "src/api/subscriptions-api";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

BatchRequestTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  updateBatchData: PropTypes.func,};

export default function BatchRequestTableRow({ row, selected, onViewRow, updateBatchData }) {
  const { batch_id, status, total_subscription_adds, total_subscription_removals, created_at, requestor_id } = row;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();


  const onProcessSubscription = async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    setError(null);
    try {
      const result = await processSubscription(batch_id);
      if (result?.status_code === 1001) {
        enqueueSnackbar("Subscription processed successfully", { variant: "success" });
        setTimeout(() => {
          closeSnackbar();
        }, 8000);
        updateBatchData();
        // navigate(PATH_DASHBOARD.subscriptions.subscriptionRequests);
      } else {
        enqueueSnackbar(`${result?.data?.message}`, { variant: "error" });
        // setFailedMessage(`${result?.data?.failed_reason}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {new Date(created_at).toLocaleDateString("en-ZA", {
              weekday: "short",
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            })}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {batch_id}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {requestor_id ? requestor_id : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {total_subscription_adds}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {total_subscription_removals}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Label
            variant="soft"
            color={
              status === "Processed"
                ? "success"
                : status === "Failed"
                  ? "error"
                  : status === "Processing"
                    ? "primary"
                    : status === "Pending"
                      ? "warning"
                      : "info"
            }
          >
            {status}
          </Label>
        </TableCell>

        <TableCell align="left">
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            onClick={(event) => {
              onProcessSubscription(event);
            }}
            disabled={status !== "New"}
          >
            Process
          </LoadingButton>
        </TableCell>
      </Link>
    </>
  );
}
