import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const location = useLocation();

  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  console.log("location: ", window.location.href)

  // Determine which logo to use based on the URL condition
  const shouldUseTupperLogo = window.location.href.includes('tuppconnect');
  const logoSrc = shouldUseTupperLogo
    ? '/logo/tupp_connect_small.png'
    : '/logo/megsapp-logo.png';

  const logo = (
    <Box
      component="img"
      src={logoSrc}
      sx={{ width: 60, height: 40, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
