import PropTypes from "prop-types";
// @mui
import { Link, TableCell, TableRow, Typography } from "@mui/material";
// import check and cross icons from material icons
import { useNavigate } from "react-router-dom/dist";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";
import Label from "src/components/label/Label";
import moment from "moment";

// ----------------------------------------------------------------------

ActiveSubscriptionTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function ActiveSubscriptionTableRow({ row, selected, onViewRow }) {
  const {
    id,
    company_name,
    product_name,
    status,
    bill_count,
    start_date,
    billing_date,
    next_billing_date,
    last_billing_date,
    msisdn,
    first_name,
    last_name,
    airtime_volume,
    data_volume,
  } = row;
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  function getOrdinalNum(n) {
    let s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }
  // const handleAppUserEditPopup = (id) => {

  // };

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell
          onClick={
            // stop propagation of the event
            (event) => {
              event.stopPropagation();
            }
          }
          sx={{
            cursor: "text",
          }}
        >
          <Typography variant="body2" noWrap>
            {first_name} {last_name}
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            {msisdn !== null ? msisdn : "-"}
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 700,
            }}
          >
            {company_name}
          </Typography>
        </TableCell>

        <TableCell
          align="left"
          sx={{
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Typography
            variant="subtitle1"
            noWrap
            sx={{
              fontSize: "0.95rem",
            }}
          >
            {product_name}
          </Typography>
          <Typography variant="body2">R {airtime_volume} airtime</Typography>
          <Typography variant="body2" noWrap>
            {data_volume ? data_volume : "0"} MB data
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            <Label variant="soft" color="secondary">
              {status}
            </Label>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            {new Date(start_date).toLocaleDateString("en-ZA", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            {getOrdinalNum(billing_date)} of the month
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            {new Date(next_billing_date).toLocaleDateString("en-ZA", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            {last_billing_date
              ? new Date(last_billing_date).toLocaleDateString("en-ZA", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 400,
            }}
          >
            {bill_count}
          </Typography>
        </TableCell>
      </Link>
    </>
  );
}
