import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { listAllTrainingVideoCategories, listAllTrainingVideos } from "src/api/megs-api-api";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useSettingsContext } from "src/components/settings";
import { useSnackbar } from "src/components/snackbar";
import AgentVideosList from "src/sections/@dashboard/agent-videos/AgentVideosList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const ConsumerVideosPage = () => {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoCategories, setVideoCategories] = useState([]);
  const [videosList, setVideosList] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchVideoCategories();
      await fetchTrainingVideos();
    })();
  }, []);

  const fetchVideoCategories = async () => {
    setLoading(true);
    const { data } = await listAllTrainingVideoCategories("consumer");
    data.unshift({ id: "All", name: "All" });
    setVideoCategories(data);
    setLoading(false);
  };

  const fetchTrainingVideos = async () => {
    setIsLoading(true);
    const { data } = await listAllTrainingVideos("consumer", i_customer);
    setVideosList(data);
    setIsLoading(false);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <Helmet>
        <title>Training Videos | megsApp</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="Training Videos"
              links={[{ name: "Consumer Videos", href: PATH_DASHBOARD.trainingVideos.consumerVideos }, { name: "Videos List" }]}
            />
          </Grid>
        </Grid>

        <AgentVideosList videoCategories={videoCategories} videosList={videosList} isLoading={isLoading} />
      </Container>
    </>
  );
};

export default ConsumerVideosPage;
