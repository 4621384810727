import PropTypes from "prop-types";
// @mui
import { Link, TableCell, TableRow, Typography } from "@mui/material";
// import check and cross icons from material icons
import { useNavigate } from "react-router-dom/dist";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";
import Label from "src/components/label/Label";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";

// ----------------------------------------------------------------------

ActiveSubscriptionsDetailsRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function ActiveSubscriptionsDetailsRow({ row, selected, onViewRow }) {
  const { pro_rata, pro_rata_days, pro_rata_percentage, amount, data_value, airtime_value, billing_date, next_billing_date, billed_date, status, retry_count } =
    row;
    console.log("Row: ", row)
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  // const handleAppUserEditPopup = (id) => {

  // };

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {pro_rata ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {pro_rata_days !== null ? pro_rata_days : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {pro_rata_percentage !== null ? pro_rata_percentage : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {amount !== null ? amount : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {data_value !== null ? data_value : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {airtime_value !== null ? airtime_value : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {billing_date !== null
              ? new Date(billing_date).toLocaleDateString("en-ZA", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {next_billing_date !== null
              ? new Date(next_billing_date).toLocaleDateString("en-ZA", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {billed_date !== null
              ? new Date(billed_date).toLocaleDateString("en-ZA", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            <Label variant="soft" color="secondary">
              {status !== null ? status : "-"}
            </Label>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {retry_count !== null ? retry_count : "-"}
          </Typography>
        </TableCell>
      </Link>
    </>
  );
}
