import * as Sentry from "@sentry/react";
import { axiosApiInstance } from "./axios";
import { axiosAuthInstance } from "./axiosAuth";

const base_url = process.env.REACT_APP_API_ENDPOINT;

export const getDistributorBalance = async () => {
  console.log("getDistributorBalance");
  try {
    const url = `${base_url}/distributor/balance`;
    const { data } = await axiosAuthInstance.post(url);
    // console.log('getDistributorBalance', data)
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getDistributorCustomersCount = async () => {
  console.log("getDistributorCustomersCount");
  try {
    const url = `${base_url}/app/customer/rica/list`;
    const { data } = await axiosAuthInstance.post(url);
    // console.log('getDistributorCustomersCount', data)
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getDistributorCustomersList = async () => {
  console.log("getDistributorCustomersList");
  try {
    const url = `${base_url}/app/customer/rica/list`;
    const { data } = await axiosAuthInstance.post(url);
    // console.log('getDistributorCustomersList', data)
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getDistributorDetails = async (i_customer) => {
  console.log("getDistributorDetails");
  try {
    const url = `${base_url}/app/distributor`;
    const { data } = await axiosAuthInstance.post(url, { i_customer });
    // console.log('getDistributorDetails', data)
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getDistributorList = async () => {
  try {
    const url = `${base_url}/app/distributor/list`;
    const { data } = await axiosAuthInstance.post(url);
    // console.log('getDistributorList', data)
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getSubDistributorList = async (i_customer) => {
  try {
    const url = `${base_url}/app/subDistributor/list`;
    const { data } = await axiosAuthInstance.post(url, { i_customer: i_customer });
    // console.log('getDistributorList', data)
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getSchoolByEMIS = async (emis) => {
  console.log("getSchoolByEMIS", emis);
  try {
    const url = `${base_url}/app/distributor/schools/get-school`;
    const { data } = await axiosApiInstance.post(url, { emis });
    // console.log('getSchoolByEMIS', data)
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const fetchSalesReport = async (external_id, start_date, end_date) => {
  try {
    console.log("fetchSalesReport", external_id, start_date, end_date);
    const url = `${base_url}/distributor/honey/sales`;
    const post_data = {
      customer_number: external_id,
      start_date: start_date,
      end_date: end_date,
    };

    const { data } = await axiosAuthInstance.post(url, post_data);
    console.log("fetchSalesReport", data);

    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const downloadSalesReport = async (external_id, start_date, end_date) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  };
  let download_url = `${base_url}/distributor/honey/report/${external_id}/${start_date}/${end_date}`;

  try {
    let data = await axiosAuthInstance.get(`${download_url}`, config);

    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${external_id}_${start_date}_${end_date}_sales_report.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // console.log('data', data)

    // return the file as start_date_end_date_sales_report.xlsx
    return data;
  } catch (ex) {
    console.error("error", ex);
    return handleErrorResponse(ex);
  }
};

export const getDistributorReportPeriods = async (distributor_i_customer) => {
  try {
    const url = `${base_url}/app/distributor/AllReportPeriod?distributor_i_customer=${distributor_i_customer}`;
    const { data } = await axiosAuthInstance.get(url);

    console.log("getDistributorReportPeriods", data);
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getDistributorReportForPeriod = async (distributor, report_period) => {
  console.log("getDistributorReportForPeriod1", distributor);
  console.log("getDistributorReportForPeriod2", report_period);

  let url = "";

  if (distributor?.i_customer === "All" && report_period === "All") {
    url = `${base_url}/app/distributor/reportPeriod`;
  } else if (distributor?.i_customer !== undefined && report_period === "All") {
    url = `${base_url}/app/distributor/reportPeriod?distributor_i_customer=${distributor?.i_customer}`;
  } else if (distributor?.i_customer !== undefined && report_period !== undefined) {
    url = `${base_url}/app/distributor/reportPeriod?distributor_i_customer=${distributor?.i_customer}&report_period=${report_period}`;
  } else {
    url = `${base_url}/app/distributor/reportPeriod`;
  }

  try {
    const { data } = await axiosAuthInstance.get(url);

    // console.log("getDistributorReportForPeriod", data);
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const getTupperwareAgentDetails = async (customer_number) => {
  try {
    const url = `${base_url}/app/tupperware_customer_number?tupperware_customer_number=${customer_number}`;
    const { data } = await axiosApiInstance.get(url);

    console.log("getTupperwareAgentDetails", data);
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

export const addTupperwareAgentDetails = async (
  external_id,
  first_name,
  last_name,
  email_address,
  id_number,
  phone_number,
  whatsapp_msisdn,
  distributor_i_customer
) => {
  try {
    const url = `${base_url}/app/tupperware_agent`;
    const { data } = await axiosApiInstance.post(url, {
      external_id,
      first_name,
      last_name,
      email_address,
      id_number,
      phone_number,
      whatsapp_msisdn: whatsapp_msisdn === "" ? phone_number : whatsapp_msisdn,
      distributor_i_customer,
    });

    console.log("addTupperwareAgentDetails", data);
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};

function handleErrorResponse(ex) {
  Sentry.captureException(ex);

  const error_response = {
    data: {
      status_code: 9000,
      message: "An error occurred. Please try again later.",
    },
  };
  return error_response;
}
