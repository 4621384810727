import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Card, Container, Stack, Typography } from "@mui/material";
// layouts
// routes
import { PATH_AUTH } from '../../routes/paths';
//

import Image from '../../components/image';

// ----------------------------------------------------------------------

export default function SelectAccount() {
  return (
    <Container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative', height: '100vh'}}>
      <Card sx={{ width: '85%', pt: 8, pb: 8, mb: 3, p:4}}>

      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={ '/assets/illustrations/megsapp-logo.png'}
          sx={{ maxWidth: 320 }}
        />
      </Box>


        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Typography sx={{textAlign: 'center'}} variant="h4">Select your account type</Typography>

          <Stack direction="column" spacing={1.5}>

              <Button
                component={RouterLink} 
                to={PATH_AUTH.loginDistributor} 
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: 'text.primary',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'text.primary',
                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  },
                }}
              >
                Distributor
              </Button>

              <Typography sx={{textAlign: 'center'}} variant="h4"> or </Typography>

              <Button
                component={RouterLink} 
                to={PATH_AUTH.loginAdmin} 
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: 'text.primary',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  '&:hover': {
                    bgcolor: 'text.primary',
                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                  },
                }}
              >
                Admin
              </Button>


          </Stack>
        </Stack>      
      </Card>
    </Container>
  );
}
