import PropTypes from "prop-types";
// @mui
import { Link, TableRow, TableCell, Typography, Button, IconButton } from "@mui/material";
// import check and cross icons from material icons
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "src/components/label";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";

// ----------------------------------------------------------------------

ProductGroupTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function ProductGroupTableRow({ row, selected, onViewRow }) {
  const { name, airtime, data, data_specials, subscriptions } = row;
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell>
          <Typography variant="body2" noWrap sx={{
            fontWeight: 600,
          }}>
            {name}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {airtime ? airtime : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {data ? data : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {data_specials ? data_specials : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {subscriptions ? subscriptions : "-"}
          </Typography>
        </TableCell>

      </Link>
    </>
  );
}
