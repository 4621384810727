import * as React from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

// Complete rows data
const rows = [
  { id: 1, type: 'Specials', description: 'Links to a specific product ID', example: '7|Data' },
  { id: 2, type: 'Adverts', description: 'Links to Ads page', example: '' },
  { id: 3, type: 'Surveys', description: 'Links to Surveys page', example: '' },
  { id: 4, type: 'Promos', description: 'Links to Promos page', example: '' },
  { id: 5, type: 'Playables', description: 'Links to Playables page', example: '' },
  { id: 6, type: 'New Update', description: 'Opens web view', example: 'URL to app store' },
  { id: 7, type: 'General', description: 'Opens web view', example: 'Any URL' },
  { id: 8, type: 'In App Page', description: 'Takes to a highlevel page in the app', example: 'Home, Inbox, Top Up, Rewards, Wallet' },
  { id: 9, type: 'In App Sub Page', description: 'Takes to a sub page in the app', example: 'Rewards|Surveys, Rewards|Promos, Rewards|Play, Top Up|Product' },
  { id: 10, type: 'App Product Pages', description: 'Takes to a product sub page', example: 'Top Up|Product|Data, Top Up|Product|Airtime, Top Up|Product|Special' },
];

export default function MetadataKeyTable() {
  return (
    <>
      <Typography variant="h6" gutterBottom component="div" sx={{ textAlign: 'center' }}>
        Metadata Key
      </Typography>
      <TableContainer component={Paper} variant="outlined" >
        <Table size="small" sx={{ minWidth: 650 }} aria-label="metadata key table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Example</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.example}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
