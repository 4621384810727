import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useRef, useState } from "react";
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField } from "src/components/hook-form";
import { useForm } from "react-hook-form";
import Quill from "react-quill";
import { createNotice, getNoticeTypes } from "src/api/notices-api";
import { getBrands } from "src/api/megs-api-api";
import { useSnackbar } from "src/components/snackbar";
import MetadataKeyTable from "../components/MetadataKeyTable";
import { useNavigate } from "react-router-dom";

function CreateNoticeDetails() {
  const [noticeState, setNoticeState] = useState({
    id: "",
    notice_type_id: "",
    message: "",
    meta_data: "",
    brand: null,
    active: 1,
  });
  const [brandData, setBrandData] = useState([]);
  const messageEditorRef = useRef();

  const navigate = useNavigate();

  const [noticeTypes, setNoticeTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    defaultValues: {
      notice_type_id: "",
      message: "",
      meta_data: "",
      brand: null,
      active: 1,
    },
  });

  const handleSwitchChange = (event) => {
    setNoticeState({
      ...noticeState,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };

  const handleMessageChange = (content_editor) => {
    if (messageEditorRef.current !== undefined) {
      const editor = messageEditorRef.current.getEditor();
      const content_raw = editor.getContents();
      const message_raw_content = content_raw.ops
        .map((raw) => raw.insert)
        .join("");
      setNoticeState({
        ...noticeState,
        message: message_raw_content,
      });
    }
    // console.log("handleMessageChange: ", noticeState);
  };

  const handleNoticeTypeIdChange = (event) => {
    setNoticeState({
      ...noticeState,
      notice_type_id: event.target.value,
    });
  };

  const handleMetaDataChange = (event) => {
    setNoticeState({
      ...noticeState,
      meta_data: event.target.value,
    });
  };

  const handleBrandChange = (event, value) => {
    // console.log("handleBrandChange: ", value);
    if (value) {
      setNoticeState({
        ...noticeState,
        brand_id: value.value,
      });
    }
  }


  const handleCreateNotice = async () => {
    try {
      setLoadingSubmit(true);
      const response = await createNotice(noticeState);
      if (response.status_code === 1001) {
        enqueueSnackbar("Notice created successfully", {
          variant: "success",
        });
        setNoticeState({
          id: "",
          notice_type_id: "",
          message: "",
          meta_data: "",
          brand: null,
          active: 1,
        });
        // navigate to the notice details page
        navigate(`/dashboard/notices/${response.data.id}`);
      } else {
        enqueueSnackbar("Error creating notice", {
          variant: "error",
        });
        console.error(response);
      }
    } catch (ex) {
      console.error(ex);
      console.error(ex.message);
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    const getNoticeTypesData = async () => {
      setLoading(true);
      const noticeTypes = await getNoticeTypes();
      // console.log("noticeTypes: ", noticeTypes);
      setNoticeTypes(noticeTypes.data);
      const brands = await getBrands();
      const brandsWithAll = [{ brand: 'All', brand_id: 'all' }, ...brands.data];
      // console.log("brandsWithAll: ", brandsWithAll);
      const brandOptions = brandsWithAll.map((brand) => ({
        label: brand.brand,
        value: brand.brand_id,
      }));
      // console.log("brandOptions: ", brandOptions);
      setBrandData(brandOptions);
      setLoading(false);
    };
    getNoticeTypesData();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card xs={12} sx={{ py: 2, px: 3 }}>
          <Typography
            paragraph
            variant="overline"
            sx={{ color: "text.disabled", pb: 2 }}
          >
            Create Notice
          </Typography>
          <Box sx={{ maxWidth: "100%", pb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="notice-type-id-label">
                    Notice Type ID
                  </InputLabel>
                  {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '56px' }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Select
                      labelId="notice-type-id-label"
                      id="notice-type-id"
                      value={noticeState.notice_type_id || ""}
                      onChange={handleNoticeTypeIdChange}
                      label="Notice Type ID"
                    >
                      {noticeTypes.map((noticeType) => (
                        <MenuItem key={noticeType.id} value={noticeType.id}>
                          {noticeType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormProvider
                  methods={methods}
                >
                  <RHFAutocomplete
                    name="brand_id"
                    label="Brand"
                    options={brandData}
                    defaultValue={noticeState.brand || ""}
                    onChange={handleBrandChange}
                  />
                </FormProvider>
              </Grid>

              <Grid item xs={12} md={5}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Meta Data"
                  name="meta_data"
                  value={noticeState.meta_data || ""}
                  onChange={handleMetaDataChange}
                // helperText="Enter the meta data for the notice."
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={noticeState.active === 1}
                        onChange={handleSwitchChange}
                        name="active"
                      />
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={10} sx={{ mt: 2 }}>
                <Quill
                  ref={messageEditorRef}
                  defaultValue={noticeState.message}
                  onChange={handleMessageChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  onClick={handleCreateNotice}
                  loading={loadingSubmit}
                >
                  Create Notice
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card 
          sx={{ py: 2, px: 3, mt: 3 }}
        >
          <Grid item xs={12}>
            <MetadataKeyTable />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CreateNoticeDetails;
