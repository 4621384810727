import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Card, Container, Grid, Stack } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { addAdminUser, addAppUser } from "src/api/megs-api-api";
import { FormProvider, RHFAutocomplete, RHFTextField } from "src/components/hook-form";
import { useSnackbar } from "src/components/snackbar";
import * as Yup from "yup";
import { PATH_DASHBOARD } from "src/routes/paths";

const AddAdminUser = () => {
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const AdminSchema = Yup.object().shape({
    name: Yup.string().required("Name and Surname is required"),
    email_address: Yup.string().required("Email Address is required"),
    msisdn: Yup.string()
      .required("Phone number is required")
      .test("phone", "Phone number is not valid", (value) => typeof value === "string" && isValidPhoneNumber(value, "ZA")),
  });

  const defaultValues = {
    name: "",
    email_address: "",
    msisdn: "",
  };

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(AdminSchema),
    defaultValues,
    shouldFocusError: true,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const onProcessSubmit = useCallback(async () => {
    console.log("Values: ", values);

    const result = await addAdminUser({
      role: "admin",
      name: values.name,
      email_address: values.email_address,
      msisdn: values.msisdn,
    });

    console.log("result", result);

    if (result.status_code === 1001) {
      navigate(PATH_DASHBOARD.admin.adminUsers);
      methods.setValue("name", "");
      methods.setValue("email_address", "");
      methods.setValue("msisdn", "");

      enqueueSnackbar(result.data, { variant: "success" });
    } else {
      enqueueSnackbar(result.data.message, { variant: "error" });
    }
  }, [values, methods, enqueueSnackbar]);

  return (
    <>
      <Card sx={{ p: 3, mb: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onProcessSubmit)}>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            {/* <Grid item xs={6} sm={6}>
              <RHFAutocomplete
                name="i_customer"
                label="Select Distributor"
                placeholder="Select Distributor"
                options={customerOptions}
                disabled={selectedRole.role !== "admin"}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <RHFAutocomplete
                name="role"
                label="Select User Role"
                placeholder="User Role"
                options={roles}
                // disabled={state.disableDistributor}
              />
            </Grid> */}
            <Grid item xs={4} sm={4}>
              <RHFTextField name="name" label="Name and Surname" />
            </Grid>
            <Grid item xs={4} sm={4}>
              <RHFTextField name="email_address" label="Email Address" />
            </Grid>
            <Grid item xs={4} sm={4}>
              <RHFTextField name="msisdn" label="Phone Number" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 2 }}>
                <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onProcessSubmit)} disabled={errors.length > 0}>
                  Add
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </>
  );
};

export default AddAdminUser;
