import { Helmet } from 'react-helmet-async';
// sections
import SelectAccount from '../../sections/auth/SelectAccount';

// ----------------------------------------------------------------------

export default function AccountSelectPage() {
  return (
    <>
      <Helmet>
        <title> Select Account | megsApp</title>
      </Helmet>

      <SelectAccount />
    </>
  );
}
