import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getBrands, getVideoDetails, listAllTrainingVideoCategories, uploadTrainingVideoDetails } from "src/api/megs-api-api";
import { useSnackbar } from "src/components/snackbar";
//   import MetadataKeyTable from "../components/MetadataKeyTable";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Iconify from "src/components/iconify";
import { UploadBox } from "src/components/upload";
import { PATH_DASHBOARD } from "src/routes/paths";

function AddTrainingVideos() {
  const navigate = useNavigate();
  const { id, category } = useParams();

  const [files, setFiles] = useState();

  const categoryTypes = [
    { label: "Agent", value: "agent" },
    { label: "Consumer", value: "consumer" },
  ];
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [selectedVideoCategory, setSelectedVideoCategory] = useState(null);
  const [name, setName] = useState(null);
  const [link, setLink] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [description, setDescription] = useState(null);
  const [videoCategories, setVideoCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [distLoading, setDistLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const selectedRole = useSelector((state) => state.auth.selectedRole);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategoryType(event.target.value);
  };

  const handleVideoCategoryChange = (event) => {
    setSelectedVideoCategory(event.target.value);
  };

  const handleDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  });

  const handleBrandChange = (event, newValue) => {
    setSelectedBrand(newValue);
  };

  const handleUploadVideo = async () => {
    if (selectedCategoryType && selectedVideoCategory && selectedBrand && name && link && files.length) {
      try {
        setLoadingSubmit(true);
        const response = await uploadTrainingVideoDetails(name, description, link, selectedVideoCategory, selectedBrand.brand_id, files);
        if (response.status_code === 1001) {
          enqueueSnackbar("Video Uploaded successfully", {
            variant: "success",
          });
          selectedCategoryType === "agent" ? navigate(PATH_DASHBOARD.trainingVideos.agentVideos) : navigate(PATH_DASHBOARD.trainingVideos.agentVideos);
        } else {
          enqueueSnackbar(response.data, {
            variant: "error",
          });
          console.error(response);
        }
      } catch (ex) {
        console.error(ex);
        console.error(ex.message);
      }
    } else {
      enqueueSnackbar("Please fill out the necessary details", {
        variant: "error",
      });
    }
    setLoadingSubmit(false);
  };

  useEffect(() => {
    if (selectedCategoryType) {
      const getVideoCategories = async () => {
        setLoading(true);
        const { data } = await listAllTrainingVideoCategories(selectedCategoryType);
        setVideoCategories(data);
        setLoading(false);
      };
      getVideoCategories();
    }
  }, [selectedCategoryType]);

  useEffect(() => {
    if (id !== undefined) {
      async function fetchVideosDetails() {
        setLoading(true);
        try {
          const response = await getVideoDetails(id);
          if (response.status_code !== 1001) {
            throw new Error("Network response was not ok");
          }
          const data = await response.data;
          setName(response.data.name);
          setSelectedCategoryType(category);
          setName(response.data.name);
          setLink(response.data.link);
          setDescription(response.data.description);
          setSelectedVideoCategory(response.data.category_id);
          setThumbnail(response.data.thumbnail);
          setFiles(null);
          console.log("Edit Response for Videos", data);
        } catch (error) {
          console.log("error", error);
        } finally {
          setLoading(false);
        }
      }
      fetchVideosDetails();
    }
  }, [category, id]);

  useEffect(() => {
    async function fetchBrands() {
      try {
        setDistLoading(true);
        const response = await getBrands();

        console.log("Brands", response.data);
        if (selectedRole.role !== "admin") {
          setBrands(response.data.filter((brand) => brand.brand === selectedRole.brand));
        } else {
          response.data.unshift({ brand: "All", brand_id: "All" });
          setBrands(response.data);
        }
        setDistLoading(false);
      } catch (error) {
        setDistLoading(false);
        console.error("Error fetching distributors:", error);
      }
    }

    fetchBrands();
  }, [selectedRole.brand, selectedRole.role]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card xs={12} sx={{ py: 2, px: 3 }}>
          <Typography paragraph variant="overline" sx={{ color: "text.disabled", pb: 2 }}>
            Upload Training Video
          </Typography>
          <Box sx={{ maxWidth: "100%", pb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                {distLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "56px" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <Autocomplete
                    value={selectedBrand}
                    onChange={handleBrandChange}
                    options={brands}
                    getOptionLabel={(option) => option.brand || ""}
                    disabled={selectedRole?.role !== "admin"}
                    disableClearable
                    renderInput={(params) => <TextField {...params} label="Select Brand" placeholder="Select Brand" fullWidth />}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel shrink id="notice-type-id-label1">
                    Category Type
                  </InputLabel>

                  <Select
                    labelId="notice-type-id-label1"
                    id="notice-type-id1"
                    value={selectedCategoryType || null}
                    onChange={handleCategoryChange}
                    label="Category Type"
                    InputLabelProps={{ shrink: true }}
                  >
                    {categoryTypes.map((category) => (
                      <MenuItem key={category.value} value={category.value}>
                        {category.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="notice-type-id-label">Video Category</InputLabel>
                  {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "56px" }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Select
                      labelId="notice-type-id-label"
                      id="notice-type-id"
                      disabled={!videoCategories.length}
                      value={selectedVideoCategory}
                      onChange={handleVideoCategoryChange}
                      label="Video Category"
                      InputLabelProps={{ shrink: true }}
                    >
                      {videoCategories?.map((video) => (
                        <MenuItem key={video.id} value={video.id}>
                          {video.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Name"
                  name="name"
                  value={name || null}
                  onChange={handleNameChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Link"
                  name="link"
                  value={link || null}
                  onChange={handleLinkChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Description"
                  name="description"
                  value={description || null}
                  onChange={handleDescriptionChange}
                  InputLabelProps={{ shrink: true }}
                  // helperText="Enter the meta data for the notice."
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <UploadBox
                  onDrop={handleDrop}
                  uploadedFile={files}
                  initialFileUrl={thumbnail}
                  onFileChange={handleDrop}
                  placeholder={
                    <Stack spacing={0.5} alignItems="center" sx={{ color: "text.disabled" }}>
                      <Iconify icon="eva:cloud-upload-fill" width={40} />
                      <Typography variant="body2">Upload file</Typography>
                      <Typography variant="caption" sx={{ mt: 1 }}>
                        Please upload a thumbnail image
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    my: 3,
                    py: 2.5,
                    width: "auto",
                    height: "auto",
                    borderRadius: 1.5,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <LoadingButton variant="contained" color="secondary" onClick={handleUploadVideo} loading={loadingSubmit}>
                  Upload Video
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AddTrainingVideos;
