import React from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import propTypes from 'prop-types';

PortHistory.propTypes = {
  port_history: propTypes.array.isRequired,
};
export default function PortHistory({ port_history }) {

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Port ID, ICCID and MSISDN</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Status Code</TableCell>
                  <TableCell>Status Message</TableCell>
                  <TableCell>Support Message</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {port_history.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <Typography
                        noWrap
                        variant="button"
                        sx={{
                          color: "text.primary",
                        }}
                      >
                        {item.port_id}
                      </Typography>
                      <Typography
                        noWrap
                        variant="subtitle2"
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        {/* if iccid is null display Not Available */}
                        {item.iccid ? item.iccid : "Not Available"}
                      </Typography>
                      <Typography
                        noWrap
                        variant="subtitle2"
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        {item.msisdn}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {item.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {item.status_code}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {item.status_message}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {item.support_message}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {new Date(item.created_at).toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};  