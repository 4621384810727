import { axiosInstance } from "./axios";
import * as Sentry from "@sentry/react";

export const getPortIns = async (startDate) => {
  try {
    const queryParams = {
      start_date: startDate,
    };

    const { data } = await axiosInstance.get("ports", { params: queryParams });
    // console.log("Data:", data[0], "Data Length:", data.length);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const closePortIn = async (msisdn) => {
  console.log("closePortIn");
  try {
    const { data } = await axiosInstance.put(`close-port`, { msisdn });
    console.log("closePortIn", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
  console.log("end closePortIn");
};

export const reopenPortIn = async (msisdn) => {
  console.log("reopenPortIn");
  try {
    const { data } = await axiosInstance.put(`reopen-port`, { msisdn });
    console.log("reopenPortIn", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
  console.log("end reopenPortIn");
};

export const reInitiatePortInStart = async (msisdn, iccid, network, whatsapp, user) => {
  console.log("reInitiatePortInStart");
  try {
    const channel = "portal";
    // check that all the variables are not null
    if (!msisdn || !iccid || !network) {
      // whatsapp is optional
      console.log("reInitiatePortInStart", "missing variables");
      console.log(`msisdn: ${msisdn} - iccid: ${iccid} - network: ${network} - whatsapp: ${whatsapp}`);
      return {
        statusCode: "1",
        statusMessage: "Failed",
        supportMessage: "Missing variables",
      };
    }

    const { data } = await axiosInstance.post(`port-in-start`, {
      msisdn,
      iccid,
      network,
      whatsapp,
      channel,
      user,
    });
    console.log("reInitiatePortInStart", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    throw ex;
  }
};

export const getActivePortStatus = async (msisdn) => {
  console.log("getActivePortStatus");
  try {
    const { data } = await axiosInstance.post(`active-port-status`, { msisdn });
    console.log("getActivePortStatus", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const portInReversal = async (
  msisdn,
  iccid,
  network,
  port_id,
  reason = "SP010 - Porting Back" // TODO: Change this to a dropdown with all the reasons
) => {
  console.log("portInReversal");
  try {
    const { data } = await axiosInstance.post(`port-in-reversal`, {
      msisdn,
      iccid,
      network,
      port_id,
      reason,
    });
    console.log("portInReversal", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const portInCancelation = async (msisdn, iccid, network, port_id) => {
  console.log("portInCancelation");
  // return {statusCode: "1", statusMessage: "Failed", supportMessage: "This is a very long message to say why the port has failed"}
  try {
    const { data } = await axiosInstance.post(`port-in-cancellation`, {
      msisdn,
      iccid,
      network,
      port_id,
    });
    console.log("portInCancelation", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getPortHistory = async (msisdn) => {
  console.log("getPortHistory");
  try {
    const { data } = await axiosInstance.post(`port-history`, { msisdn });
    console.log("getPortHistory", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const simSwap = async (msisdn, iccid, swap_reason) => {
  console.log("simSwap");
  try {
    const { data } = await axiosInstance.post(`sim-swap`, { msisdn, iccid, swap_reason });
    // console.log("simSwap", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const portInStart = async (msisdn, iccid, network, whatsapp, user) => {
  console.log("PortInStart");
  try {
    const channel = "portal";
    // check that all the variables are not null
    if (!msisdn || !iccid || !network) {
      // whatsapp is optional
      console.log("PortInStart", "missing variables");
      console.log(`msisdn: ${msisdn} - iccid: ${iccid} - network: ${network} - whatsapp: ${whatsapp}`);
      return {
        statusCode: "1",
        statusMessage: "Failed",
        supportMessage: "Missing variables",
      };
    }

    const { data } = await axiosInstance.post(`port-in-start`, {
      msisdn,
      iccid,
      network,
      whatsapp,
      channel,
      user,
    });
    console.log("PortInStart: ", data);
    return data;
    // return { statusCode: "1", statusMessage: 'Error!', supportMessage: 'There has been an error' };
    // return { statusCode: "0", statusMessage: 'Success!', supportMessage: 'Port in started successfully' };
    // return { statusCode: null, statusMessage: null, supportMessage: null };
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    throw ex;
  }
};
