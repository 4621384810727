import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

// @mui
import { Button, Card, Checkbox, Divider, FormControlLabel, Grid, InputAdornment, Stack, Switch, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "react-redux";
import { updateProductGroupMapping, updateProductGroupStatus } from "src/api/megs-api-api";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

ProductGroupDetails.propTypes = {
  productGroupData: PropTypes.object,
};

export default function ProductGroupDetails({ productGroupData, products, productGroupId }) {
  const { name, is_active } = productGroupData;
  console.log("Details: ", productGroupData);
  console.log("Products: ", products);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [associatedProduct, setAssociatedProduct] = useState([]);
  const [associatedId, setAssociatedId] = useState([]);
  const [associatedProductData, setAssociatedProductData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [productItemData, setProductItemData] = useState([]);
  const [expiryGroups, setExpiryGroups] = useState([]);
  const [expiryGroupsData, setExpiryGroupsData] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState();

  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const productIds = [];
    const productsGroupObject = productGroupData?.product_group_products?.map((productGroup) => {
      productIds.push(productGroup.product_id);
      const { name, ...newProductGroup } = productGroup;
      newProductGroup.isDeleted = false;
      return newProductGroup;
    });
    setAssociatedProductData(productsGroupObject);
    setAssociatedProduct(productIds);
    setAssociatedId(productIds);
  }, [productGroupData]);

  useEffect(() => {
    products && setExpiryGroups(Object.keys(products));
    console.log("expiryGroups", Object.keys(products));
    products && setExpiryGroupsData(Object.keys(products));
    products && setProductsData(products);
    products && setProductItemData(products);
  }, [products]);

  const handleAssociation = (e, id) => {
    setIsDirty(true);
    setAssociatedProduct(e.target.checked ? [...associatedProduct, id] : associatedProduct.filter((groupId) => groupId !== id));
    if (associatedId.includes(id)) {
      const newData = associatedProductData.map((data) => {
        if (data.product_id == id) {
          data.isDeleted = e.target.checked ? false : true;
        }
        return data;
      });
      setAssociatedProductData(newData);
    } else {
      let productNew = {
        id: null,
        product_id: id,
        product_group_id: parseInt(productGroupId),
        isDeleted: false,
      };
      setAssociatedProductData(e.target.checked ? [...associatedProductData, productNew] : associatedProductData.filter((data) => data.product_id !== id));
    }
  };

  const updateProductGroups = async () => {
    try {
      setIsLoading(true);
      const response = await updateProductGroupMapping(associatedProductData);
      console.log("response", response);
      if (response.status_code === 1001) {
        enqueueSnackbar("Products updated successfully!", { variant: "success" });
        setTimeout(() => {
          closeSnackbar();
        }, 4000);
        navigate(PATH_DASHBOARD.products.viewProductGroup(productGroupId));
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    setIsActive(is_active);
  }, [is_active]);

  useEffect(() => {
    let filteredProductsGroups = {};
    expiryGroupsData.map((data) => {
      const filteredProductsData = productItemData[data]?.filter((product) => {
        const isSearchMatch = product?.name?.toLowerCase().includes(search.toLowerCase());
        return isSearchMatch;
      });
      if (filteredProductsData.length) {
        filteredProductsGroups[data] = filteredProductsData;
      }
    });
    setProductsData(filteredProductsGroups);
    setExpiryGroups(Object.keys(filteredProductsGroups));
  }, [search]);

  const handleSwitchChange = async (event) => {
    const newStatus = event.target.checked ? 1 : 0;
    setIsActive(newStatus);
    const response = await updateProductGroupStatus({ id: parseInt(productGroupId), is_active: newStatus });
    if (response.status_code !== 1001) {
      enqueueSnackbar("Unable to update status", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setTimeout(() => {
        closeSnackbar();
      }, 4000);
    } else {
      enqueueSnackbar("Status updated successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setTimeout(() => {
        closeSnackbar();
      }, 4000);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ py: 3, px: 3 }}>
            <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
              Product Group Details
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Name</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {name}
                </Typography>
              </Grid>

             
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Card sx={{ py: 3, px: 3 }}> */}
          <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
            Associated Products
          </Typography>
          
          {expiryGroups.map((data) => (
            <Grid item xs={12} sm={12} key={data}>
              <Card sx={{ py: 3, px: 3, my: 2 }}>
                <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                  {data === "null" ? "Non Expiring Products" : `${data.split('_').join(' ')}`}
                </Typography>
                <Grid container spacing={3}>
                  {Array.isArray(productsData[data].product) && productsData[data].product.map((group) => (
                    <Grid item xs={12} md={3} key={group.id}>
                      <FormControlLabel
                        control={<Checkbox checked={associatedProduct.includes(group.id)} onChange={(e) => handleAssociation(e, group.id)} />}
                        label={group.name}
                      />
                    </Grid>
                  ))}
                  {/* divider */}
                  <Divider />
                  {Array.isArray(productsData[data].vouchers) && productsData[data].vouchers.length > 0 &&
                    <Grid item xs={12}>
                      <Typography paragraph variant="overline" sx={{ color: "text.disabled", mb: -2 }}>
                        Vouchers
                      </Typography>
                    </Grid>
                  }
                  {Array.isArray(productsData[data].vouchers) && productsData[data].vouchers.map((group) => (
                    <React.Fragment key={group.id}>

                      <Grid item xs={12} md={3}>
                        <FormControlLabel
                          control={<Checkbox checked={associatedProduct.includes(group.id)} onChange={(e) => handleAssociation(e, group.id)} />}
                          label={group.name}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                  {/* divider */}
                  <Divider orientation="horizontal" sx={{
                    opacity: 0.5,
                    color: "text.disabled",
                  }} />
                  {Array.isArray(productsData[data].promo_vouchers) && productsData[data].promo_vouchers.length > 0 &&
                    <Grid item xs={12}>
                      <Typography paragraph variant="overline" sx={{ color: "text.disabled", mb: -2 }}>
                        Promo Vouchers
                      </Typography>
                    </Grid>
                  }

                  {Array.isArray(productsData[data].promo_vouchers) && productsData[data].promo_vouchers.map((group) => (
                    <React.Fragment key={group.id}>
                      <Grid item xs={12} md={3}>
                        <FormControlLabel
                          control={<Checkbox checked={associatedProduct.includes(group.id)} onChange={(e) => handleAssociation(e, group.id)} />}
                          label={group.name}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                  {/* divider */}
                  <Divider />
                  {Array.isArray(productsData[data]) && productsData[data].map((group) => (
                    <Grid item xs={12} md={3} key={group.id}>
                      <FormControlLabel
                        control={<Checkbox checked={associatedProduct.includes(group.id)} onChange={(e) => handleAssociation(e, group.id)} />}
                        label={group.name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          ))}

          <Grid item xs={12} sm={12}>
            <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 2 }}>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                disabled={!isDirty}
                onClick={() => {
                  updateProductGroups();
                }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
          {/* </Card> */}
        </Grid>
      </Grid>
    </>
  );
}
