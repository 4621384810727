import { axiosAuthInstance } from "./axiosAuth";
import * as Sentry from "@sentry/react";

const base_url = process.env.REACT_APP_API_ENDPOINT;

export const getPortalNoticesList = async () => {
  // console.log("getPortalNoticesList");
  try {
    const url = `${base_url}/app/notices/portal`;
    const { data } = await axiosAuthInstance.post(url);
    // console.log("getNoticeList: ", data);
    return data;
  } catch (ex) {
    Sentry.captureException(ex);
  }
};

export const getDistributorNoticeDetails = async (id) => {
  console.log("getNoticeDetails");
  const body = {
    id: id,
  };
  try {
    const url = `${base_url}/app/notices/portal/details`;
    const { data } = await axiosAuthInstance.post(url, body);
    // console.log("getNoticeDetails: ", data);
    return data;
  } catch (ex) {
    Sentry.captureException(ex);
  }
};

export const togglePortalNoticeStatus = async (id) => {
  console.log("toggleNoticeStatus");
  try {
    const body = {
      id: id,
    };
    const url = `${base_url}/app/notices/portal/toggle`;
    const { data } = await axiosAuthInstance.post(url, body);
    // console.log("toggleNoticeStatus: ", data);
    return data;
  } catch (ex) {
    Sentry.captureException(ex);
  }
};

export const updatePortalNoticeDetails = async (body) => {
  console.log("updateNoticeDetails");

  try {
    const url = `${base_url}/app/notices/portal`;
    const { data } = await axiosAuthInstance.put(url, body);
    // console.log("updateNoticeDetails: ", data);
    return data;
  } catch (ex) {
    Sentry.captureException(ex);
  }
};

export const createPortalNotice = async (body) => {
  console.log("createNotice");

  try {
    const url = `${base_url}/app/notices/portal/create`;
    const { data } = await axiosAuthInstance.post(url, body);
    // console.log("createNotice: ", data);
    return data;
  } catch (ex) {
    Sentry.captureException(ex);
  }
};

export const getPortalNoticeTypes = async () => {
  console.log("getNoticeTypes");
  try {
    const url = `${base_url}/app/notices/portal/types`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getNoticeTypes: ", data);
    return data;
  } catch (ex) {
    Sentry.captureException(ex);
  }
};

export const updateNoticeOrder = async (id, index) => {
  console.log("updateNoticeOrder");
  try {
    const body = {
      id: id,
      index: index,
    };
    const url = `${base_url}/app/notices/customer/order`;
    const { data } = await axiosAuthInstance.post(url, body);
    // console.log("toggleNoticeStatus: ", data);
    return data;
  } catch (ex) {
    Sentry.captureException(ex);
  }
};
