import { Button, Container, Grid, TextField, Stack, Card, Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";
import { Link } from "react-router-dom";
import Iconify from "src/components/iconify";
import { useSelector } from "react-redux";
import { useSnackbar } from "src/components/snackbar";
import ProductList from "src/sections/@dashboard/products/ProductList";
import ProductGroupList from "src/sections/@dashboard/product-group/ProductGroupList";

const ProductGroupPage = () => {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Helmet>
        <title>App Users | megsApp</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="Product Groups"
              links={[{ name: "Product Group", href: PATH_DASHBOARD.products.productGroupList }, { name: "Product Group List" }]}
            />
          </Grid>
        </Grid>

        <ProductGroupList />
      </Container>
    </>
  );
};

export default ProductGroupPage;
