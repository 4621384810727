import { Button, Container, Grid, TextField, Stack, Card, Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";
import { Link } from "react-router-dom";
import Iconify from "src/components/iconify";
import { useSelector } from "react-redux";
import { useSnackbar } from "src/components/snackbar";
import ProductList from "src/sections/@dashboard/products/ProductList";
import { listAllProductGroup, listAllProductTypes } from "src/api/megs-api-api";
import TrainingVideosList from "src/sections/@dashboard/training-videos/TrainingVideosList";

const TrainingVideosPage = () => {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchProductTypes();
    })();
  }, []);

  const fetchProductTypes = async () => {
    const { data } = await listAllProductTypes();
    data.unshift({ id: "All", name: "All" });
    setProductTypes(data);
  };

  return (
    <>
      <Helmet>
        <title>App Users | megsApp</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="Training Videos"
              links={[{ name: "Training Videos", href: PATH_DASHBOARD.products.root }, { name: "Training Videos List" }]}
            />
          </Grid>
        </Grid>

        <TrainingVideosList />
      </Container>
    </>
  );
};

export default TrainingVideosPage;
