import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Grid,
  Autocomplete,
  TextField,
  Divider,
  Tabs,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { listActiveSubscriptions, listAdminAppUsers, listAllAppUsers, listAppUsers } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import ActiveSubscriptionTableRow from "./ActiveSubscriptionTableRow";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
export default function ActiveSubscriptionList({ distributors, selectedRole, onChangeDistributor }) {
  const [activeSubscription, setActiveSubscription] = useState([]);
  const [activeSubscriptionData, setActiveSubscriptionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { i_customer } = selectedRole;
  const [selectedDistributor, setSelectedDistributor] = useState();
  const navigate = useNavigate();

  // const [selectedDistributor, setSelectedDistributor] = useState(selectedRole.role === "admin" ? null : distributors[0]);

  // const handleDistributorChange = (event, newValue) => {
  //   console.log("Distributor Values", newValue);
  //   setSelectedDistributor(newValue);
  // };

  const TABLE_HEAD = [
    { id: "subscriber", label: "Subscriber", align: "left" },
    { id: "subscription", label: "Subscription", align: "left" },
    { id: "status", label: "Status", align: "left" },
    // { id: "role", label: "Role", align: "left" },
    { id: "start_date", label: "Start Date", align: "left" },
    { id: "billing_date", label: "Billing Date", align: "left" },
    { id: "next_billing_date", label: "Next Billing Date", align: "left" },
    { id: "last_billing_date", label: "Last Billing Date", align: "left" },
    { id: "bill_count", label: "Bill Count", align: "left" },
  ];

  const TABS = [{ value: "all", label: "All", color: "primary", count: activeSubscription.length }];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    if (selectedDistributor) {
      const distributorId = selectedRole.role === "admin" ? selectedDistributor?.i_customer : selectedRole.i_customer;
      async function fetchActiveSubscription() {
        setIsLoading(true);
        setError(null);
        try {
          const response = await listActiveSubscriptions(distributorId);
          console.log("response", response);
          if (response.status_code !== 1001) {
            throw new Error("Network response was not ok");
          }
          const data = await response.data;
          console.log("App Users", data);
          setActiveSubscription(data);
          setActiveSubscriptionData(data);
        } catch (error) {
          console.log("error", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }

      fetchActiveSubscription();
      onChangeDistributor(selectedDistributor);
    }
  }, [selectedDistributor]);

  const [search, setSearch] = useState("");

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDistributorChange = (event, newValue) => {
    setSelectedDistributor(newValue);
  };

  const handleViewRow = useCallback(
    (id) => {
      navigate(PATH_DASHBOARD.subscriptions.view(id));
    },
    [navigate]
  );

  useEffect(() => {
    if (distributors.length) {
      if (selectedRole.role === "admin") {
        setSelectedDistributor({ company_name: "All", i_customer: "All" });
      } else {
        const distributor = distributors.find((d) => d.i_customer === selectedRole.i_customer);
        setSelectedDistributor(distributor);
      }
    }
  }, [distributors]);

  useEffect(() => {
    const filteredSubsData = activeSubscriptionData?.filter((subsData) => {
      console.log("subsData", subsData);
      if (
        subsData.company_name.toLowerCase().includes(search.toLowerCase()) ||
        subsData.first_name.toLowerCase().includes(search.toLowerCase()) ||
        subsData.last_name.toLowerCase().includes(search.toLowerCase()) ||
        subsData.msisdn.toLowerCase().includes(search.toLowerCase())
      ) {
        return subsData;
      }
    });
    setActiveSubscription([...filteredSubsData]);
  }, [search]);

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.05)",
          zIndex: 9999,
        }}
      >
        <LoadingScreen />
      </Box>
    );

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        />{" "}
        <Grid container spacing={2}>
          {selectedRole.role === "admin" && (
            <Grid item sx={{ m: 2 }} xs={6}>
              <Autocomplete
                value={selectedDistributor}
                onChange={handleDistributorChange}
                options={distributors}
                getOptionLabel={(option) => option.company_name || ""}
                renderInput={(params) => <TextField {...params} label="Select Distributor" placeholder="Select Distributor" fullWidth />}
              />
            </Grid>
          )}
          <Grid item sx={{ m: 2 }} xs={selectedRole.role === "admin" ? 5 : 12}>
            <TextField
              fullWidth
              value={search}
              placeholder="Search..."
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={activeSubscription.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {activeSubscription.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((subscription, index) => (
                  <ActiveSubscriptionTableRow key={index} row={subscription} onViewRow={() => handleViewRow(subscription.id)} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, activeSubscription.length)} />
                <TableNoData isNotFound={activeSubscription.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={activeSubscription.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
