import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
// @mui
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
// sections
import { getProductDetails, getProductGroupDetails, listAllProductGroup, listAllProducts, listAllProductsByGroup } from "src/api/megs-api-api";
import ProductDetails from "src/sections/@dashboard/products/details";
import ProductGroupDetails from "src/sections/@dashboard/product-group/details";

// ----------------------------------------------------------------------

export default function ProductGroupDetailsPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();
  const [productGroupData, setProductGroupData] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchProductGroupDetails();
      await fetchProducts();
      setLoading(false);
    })();
  }, []);

  const fetchProductGroupDetails = async () => {
    const { data } = await getProductGroupDetails(id);
    setProductGroupData(data);
  };
  const fetchProducts = async () => {
    const { data } = await listAllProductsByGroup();
    setProducts(data);
  };

  return (
    <>
      <Helmet>
        <title> Rica Details | megsApp Portal</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Product Group Details"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Product Group List",
              href: PATH_DASHBOARD.products.productGroupList,
            },
            { name: `${id}` },
          ]}
        />

        {productGroupData && <ProductGroupDetails productGroupData={productGroupData} products={products} productGroupId={id} />}
      </Container>
    </>
  );
}
