import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useRef, useState } from "react";
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField } from "src/components/hook-form";
import { useForm } from "react-hook-form";
import Quill from "react-quill";
import { createNotice, getNoticeTypes } from "src/api/notices-api";
import { getBrands } from "src/api/megs-api-api";
import { useSnackbar } from "src/components/snackbar";
import MetadataKeyTable from "../components/MetadataKeyTable";
import { useNavigate } from "react-router-dom";
import { createPortalNotice, getPortalNoticeTypes } from "src/api/portal-notices-api";
import { PATH_DASHBOARD } from "src/routes/paths";
import { getDistributorList } from "src/api/distributor-api";
import { useSelector } from "react-redux";

function CreateDistributorNoticeDetails() {
  const [noticeState, setNoticeState] = useState({
    // id: "",
    distributor_i_customer: "",
    notice_type_id: "",
    message: "",
    heading: "",
    role: null,
    active: 1,
  });
  const [brandData, setBrandData] = useState([]);
  const messageEditorRef = useRef();

  const navigate = useNavigate();

  const [noticeTypes, setNoticeTypes] = useState([]);
  const [roleTypes, setRoleTypes] = useState([
    { label: "All", value: "all" },
    { label: "Admin", value: "admin" },
    { label: "Distributor", value: "distributor" },
    { label: "Sub Distributor", value: "sub-distributor" },
    { label: "Support Agent", value: "support_agent" },
  ]);
  const [loading, setLoading] = useState(false);
  const [distLoading, setDistLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const selectedRole = useSelector((state) => state.auth.selectedRole);

  const [distributors, setDistributors] = useState([]);

  const [selectedDistributor, setSelectedDistributor] = useState();

  const { i_customer } = selectedRole;

  const methods = useForm({
    defaultValues: {
      notice_type_id: "",
      message: "",
      heading: "",
      role: null,
      active: 1,
    },
  });

  const handleSwitchChange = (event) => {
    setNoticeState({
      ...noticeState,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };

  const handleMessageChange = (content_editor) => {
    if (messageEditorRef.current !== undefined) {
      const editor = messageEditorRef.current.getEditor();
      const content_raw = editor.getContents();
      const message_raw_content = content_raw.ops.map((raw) => raw.insert).join("");
      setNoticeState({
        ...noticeState,
        message: message_raw_content,
      });
    }
    // console.log("handleMessageChange: ", noticeState);
  };

  const handleNoticeTypeIdChange = (event) => {
    setNoticeState({
      ...noticeState,
      notice_type_id: event.target.value,
    });
  };

  const handleHeadingDataChange = (event) => {
    setNoticeState({
      ...noticeState,
      heading: event.target.value,
    });
  };

  const handleRoleChange = (event) => {
    console.log("handleRoleChange: ", event.target.value);
    setNoticeState({
      ...noticeState,
      role: event.target.value,
    });
  };

  const handleCreateNotice = async () => {
    try {
      setLoadingSubmit(true);
      const response = await createPortalNotice(noticeState);
      if (response.status_code === 1001) {
        enqueueSnackbar("Notice created successfully", {
          variant: "success",
        });
        setNoticeState({
          distributor_i_customer: "",
          id: "",
          notice_type_id: "",
          message: "",
          meta_data: "",
          brand: null,
          active: 1,
        });
        // navigate to the notice details page
        navigate(PATH_DASHBOARD.admin.distributorNotices);
      } else {
        enqueueSnackbar("Error creating notice", {
          variant: "error",
        });
        console.error(response);
      }
    } catch (ex) {
      console.error(ex);
      console.error(ex.message);
    }
    setLoadingSubmit(false);
  };

  const handleDistributorChange = (event, newValue) => {
    setNoticeState({
      ...noticeState,
      distributor_i_customer: newValue?.i_customer,
    });
    setSelectedDistributor(newValue);
  };

  useEffect(() => {
    const getNoticeTypesData = async () => {
      setLoading(true);
      const noticeTypes = await getPortalNoticeTypes();
      // console.log("noticeTypes: ", noticeTypes);
      setNoticeTypes(noticeTypes.data);
      setLoading(false);
    };
    getNoticeTypesData();
  }, []);

  // useEffect(() => {
  //   if(selectedRole.role !== "admin") {
  //     role
  //   }
  // }, [selectedRole])

  useEffect(() => {
    async function fetchDistributors() {
      try {
        setDistLoading(true);
        const response = await getDistributorList();
        console.log("Distributor Response", response);
        if (selectedRole.role !== "admin") {
          setDistributors(response.data.filter((distributor) => distributor.i_customer === i_customer));
          setSelectedDistributor(response.data.filter((distributor) => distributor.i_customer === i_customer).pop());
        } else {
          response.data.unshift({ i_customer: 0, company_name: "All" });
          setSelectedDistributor({ i_customer: 0, company_name: "All" });
          setDistributors(response.data);
        }
        setDistLoading(false);
      } catch (error) {
        setDistLoading(false);
        console.error("Error fetching distributors:", error);
      }
    }

    fetchDistributors();
  }, []);

  useEffect(() => {
    setNoticeState({
      ...noticeState,
      distributor_i_customer: selectedDistributor?.i_customer,
    });
  }, [selectedDistributor]);

  useEffect(() => {
    if (selectedRole.role !== "admin") {
      const index = roleTypes.findIndex((x) => x.value == "admin");
      roleTypes.splice(index, 1);
    }
  }, [selectedRole]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card xs={12} sx={{ py: 2, px: 3 }}>
          <Typography paragraph variant="overline" sx={{ color: "text.disabled", pb: 2 }}>
            Create Notice
          </Typography>
          <Box sx={{ maxWidth: "100%", pb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                {distLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "56px" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <Autocomplete
                    value={selectedDistributor}
                    onChange={handleDistributorChange}
                    options={distributors}
                    getOptionLabel={(option) => option.company_name || ""}
                    disabled={selectedRole?.role !== "admin"}
                    // sx={{
                    //   width: "25%",
                    // }}
                    disableClearable
                    renderInput={(params) => <TextField {...params} label="Select Distributor" placeholder="Select Distributor" fullWidth />}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="notice-type-id-label">Level</InputLabel>
                  {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "56px" }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Select
                      labelId="notice-type-id-label"
                      id="notice-type-id"
                      value={noticeState.notice_type_id || ""}
                      onChange={handleNoticeTypeIdChange}
                      label="Level"
                    >
                      {noticeTypes.map((noticeType) => (
                        <MenuItem key={noticeType.id} value={noticeType.id}>
                          {noticeType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="notice-type-id-label">Role</InputLabel>

                  <Select labelId="notice-type-id-label" id="notice-type-id" value={noticeState.role || ""} onChange={handleRoleChange} label="Role">
                    {roleTypes.map((roleType) => (
                      <MenuItem key={roleType.value} value={roleType.value}>
                        {roleType.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Heading"
                  name="heading"
                  value={noticeState.heading || ""}
                  onChange={handleHeadingDataChange}
                  // helperText="Enter the meta data for the notice."
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <FormControlLabel control={<Switch checked={noticeState.active === 1} onChange={handleSwitchChange} name="active" />} label="Active" />
                </Grid>
              </Grid>
              <Grid item xs={12} md={10} sx={{ mt: 2 }}>
                <Quill ref={messageEditorRef} defaultValue={noticeState.message} onChange={handleMessageChange} />
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <LoadingButton variant="contained" color="secondary" onClick={handleCreateNotice} loading={loadingSubmit}>
                  Create Notice
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CreateDistributorNoticeDetails;
