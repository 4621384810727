import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CustomDialog from "src/components/dialog/CustomDialog";

// @mui
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatMsisdn } from "src/api/megs-api-api";
import {
  closePortIn,
  getPortHistory,
  portInCancelation,
  portInReversal,
  reopenPortIn,
} from "src/api/soa-api";

import { useSnackbar } from "../../../../components/snackbar";
import ReinitiatePortIn from "./components/ReinitiatePortIn";
import PortHistory from "./components/PortHistory";



PortDetails.propTypes = {
  portStatus: PropTypes.object,
  customer: PropTypes.object,
  port_in_state: PropTypes.string,
};

export default function PortDetails({ portStatus, customer, port_in_state, msisdn }) {
  const [selectedValue, setSelectedValue] = useState("no");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [port_history, setPortHistory] = useState([]);

  const [openReinitiatePort, setOpenReinitiatePort] = useState(false);
  const [openClosePort, setOpenClosePort] = useState(false);
  const [openReopenPort, setOpenReopenPort] = useState(false);
  const [openCancelPort, setOpenCancelPort] = useState(false);
  const [openReversePort, setOpenReversePort] = useState(false);
  const [portInState, setPortInState] = useState(port_in_state);
  const [dialog, setDialog] = useState({
    open: false,
    action: "",
    handleClose: null,
  });

  const { enqueueSnackbar } = useSnackbar();


  const openDialog = (action, handleClose) => {
    setDialog({ open: true, action, handleClose });
  };

  const handleCloseDialog = async (value) => {
    if (dialog.handleClose) {
      await dialog.handleClose(value);
    }
    setDialog({ ...dialog, open: false });
  };



  const handleCancelPortClose = async (value) => {
    setOpenCancelPort(false);
    if (value === "yes") {
      const response = await cancelPort();
      if (response.statusCode === "0") {
        enqueueSnackbar("Cancel Port sent successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } else {
      console.log("Cancel Port cancelled");
      enqueueSnackbar("Cancel Port cancelled", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleReversePortClose = async (value) => {
    setOpenReversePort(false);
    if (value === "yes") {
      const response = await reversePort();
      if (response.statusCode === "0") {
        enqueueSnackbar("Port Reversal sent successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } else {
      console.log("Reverse Port cancelled");
      enqueueSnackbar("Reverse Port cancelled", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleClosePortClose = async (value) => {
    setOpenClosePort(false);
    if (value === "yes") {
      const status = await closePort();
      if (status.port_in_state === "closed") {
        setPortInState("closed");
        console.log("Close Port");
        enqueueSnackbar("Closed Port", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        console.log("Close Port failed", status);
        enqueueSnackbar("Close Port failed", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } else {
      console.log("Close Port cancelled");
      enqueueSnackbar("Close Port cancelled", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleReopenPortClose = async (value) => {
    setOpenReopenPort(false);
    if (value === "yes") {
      const status = await reopenPort();
      if (status.port_in_state === "open") {
        console.log("Reopen Port");
        setPortInState("open");
        enqueueSnackbar("Reopened Port", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        console.log("Reopen Port failed", status);
        enqueueSnackbar("Reopen Port failed", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } else {
      console.log("Reopen Port cancelled");
      enqueueSnackbar("Reopen Port cancelled", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const { statusMessage, supportMessage } =
    portStatus;

  // const { msisdn } = supportMessage.equipmentList[0];

  useEffect(() => {
    (async () => {
      if (customer) {
        if (customer.whatsapp_msisdn && customer.whatsapp_msisdn.length !== 0) {
          const response = await formatMsisdn(customer.whatsapp_msisdn);
          console.log("response", response);
          if (response.status_code === 1001) {
            customer.whatsapp_msisdn = response.data;
          }
        }

      }
      const history = await getPortHistory(msisdn);
      console.log("setPortHistory", history);
      setPortHistory(history);
    })();
  }, [customer]);

  const reversePort = async () => {
    try {
      const status = await portInReversal(
        msisdn,
        customer.sim_serial_number,
        customer.port_network,
        supportMessage.equipmentList[0].portId
      );
      if (status.statusCode === "0") {
        enqueueSnackbar("Port Reversal Successful", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        enqueueSnackbar("Port Reversal Failed", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
      return status;
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Port Reversal Failed", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const cancelPort = async () => {
    try {
      const status = await portInCancelation(
        msisdn,
        customer.sim_serial_number,
        customer.port_network,
        supportMessage.equipmentList[0].portId
      );
      console.log("status", status);
      if (status.statusCode === "0") {
        enqueueSnackbar("Canceled Port sent successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        enqueueSnackbar(
          `Cancellation of Port failed with status code ${status.statusCode}`,
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      }
      return status;
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Cancellation of Port failed", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const closePort = async () => {
    console.log("closePort");
    console.log("msisdn", msisdn);
    try {
      const status = await closePortIn(msisdn);
      return status;
    } catch (error) {
      console.error(error);
    }
  };

  const reopenPort = async () => {
    console.log("reopenPort");
    console.log("msisdn", msisdn);
    try {
      const status = await reopenPortIn(msisdn);
      return status;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        {customer && (
          <Grid item xs={12} md={12}>
            <Card sx={{ py: 3, px: 3 }}>
              <Typography
                paragraph
                variant="overline"
                sx={{ color: "text.disabled" }}
              >
                Customer Info
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Name & Surname</Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {customer.first_name} {customer.last_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">
                    Alternative Contact Number
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {customer.alt_phone_number}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Whatsapp Number</Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {customer.whatsapp_msisdn}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Email</Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {customer.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Current Network</Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {customer.port_network}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">ICCID</Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {customer.sim_serial_number
                      ? customer.sim_serial_number
                      : customer.iccid}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Card sx={{ py: 3, px: 3 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: "text.disabled" }}
            >
              Active Port Status
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2">Status Message</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {statusMessage}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2">Msisdn</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {supportMessage.equipmentList[0].msisdn}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2">Partner Id</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {supportMessage.equipmentList[0].partnerId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2">Port Id</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {supportMessage.equipmentList[0].portId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2">Port Type</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {supportMessage.equipmentList[0].portType}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2">Current Status</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {supportMessage.equipmentList[0].status}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ py: 3, px: 3, height: "100%" }}>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: "text.disabled" }}
            >
              Port Actions
            </Typography>

            <Grid
              container
              spacing={3}
              justify="center"
              alignItems="center"
              sx={{ my: 3 }}
            >
              {customer && (
                <>
                  <Grid item xs={12} md={6}>
                    <LoadingButton
                      onClick={() =>
                        openDialog("reverse", handleReversePortClose)
                      }
                      variant="contained"
                      color={"info"}
                      loading={isSubmitting}
                      fullWidth
                    >
                      Reverse Port In Start
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LoadingButton
                      onClick={() =>
                        openDialog("cancel", handleCancelPortClose)
                      }
                      variant="contained"
                      color={"warning"}
                      loading={isSubmitting}
                      fullWidth
                    >
                      Cancel Port In Start
                    </LoadingButton>
                  </Grid>
                  {portInState !== "closed" && (
                    <Grid item xs={12} md={6}>
                      <LoadingButton
                        onClick={() =>
                          openDialog("close", handleClosePortClose)
                        }
                        variant="contained"
                        color={"error"}
                        loading={isSubmitting}
                        fullWidth
                      >
                        Close Port Request
                      </LoadingButton>
                    </Grid>
                  )}
                  {portInState === "closed" && (
                    <Grid item xs={12} md={6}>
                      <LoadingButton
                        onClick={() =>
                          openDialog("re-open", handleReopenPortClose)
                        }
                        variant="contained"
                        color={"success"}
                        loading={isSubmitting}
                        fullWidth
                      >
                        Re-open Port Request
                      </LoadingButton>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Card>
        </Grid>

        {customer && (
          <ReinitiatePortIn
            open={openReinitiatePort}
            setOpenReinitiatePort={setOpenReinitiatePort}
            openDialog={openDialog}
            customer={customer}
            getPortHistory={getPortHistory}
            setPortHistory={setPortHistory}
            disabled={supportMessage.equipmentList[0].status === "Requested" || supportMessage.equipmentList[0].status === "Complete"}
          />
        )
        }

        <Grid item xs={12}>
          <Card sx={{ py: 3, px: 3 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: "text.disabled" }}
            >
              Port History
            </Typography>

            {port_history && port_history.length !== 0 && (
              <PortHistory port_history={port_history} />
            )}
          </Card>
        </Grid>
        <CustomDialog
          selectedValue={selectedValue}
          open={dialog.open}
          action={dialog.action}
          onClose={handleCloseDialog}
        />
      </Grid>
    </>
  );
}
