import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';

function CustomDialog({ onClose, selectedValue, open, action }) {

  const [clicked, setClicked] = React.useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleYesClick = () => {
    setClicked(true);
    onClose('yes');
  };

  const handleNoClick = () => {
    onClose('no');
  };

  const title = `Are you sure you want to ${action} the port?`;
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={handleYesClick} color="primary" disabled={clicked}>
          Yes
        </Button>
        <Button variant="contained" autoFocus onClick={handleNoClick} color="error">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
