import axios from "axios";

// console.log(process.env.REACT_APP_SOA_API_ENDPOINT)
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SOA_API_ENDPOINT,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic c29hQG10bi5jb206c1JvZyFhRyNKOVFzV3M2M3BWOGg=",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosApiInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default axiosInstance;
