import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Grid,
  Autocomplete,
  TextField,
  Divider,
  Tabs,
  InputAdornment,
  LinearProgress,
  Tab,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { getBatchRequests, listActiveSubscriptions, listAdminAppUsers, listAllAppUsers, listAppUsers } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import ActiveSubscriptionTableRow from "./ActiveSubscriptionTableRow";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import BatchRequestTableRow from "./BatchRequestTableRow";
import { useSelector } from "react-redux";
import Label from "src/components/label";
export default function BatchRequestList({ batchData }) {
  const [batchRequestsData, setBatchRequestsData] = useState([]);
  const [batchRequestsDataForTabs, setBatchRequestsDataForTabs] = useState([]);
  const [batchRequests, setBatchRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDistributor, setSelectedDistributor] = useState();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const navigate = useNavigate();
  const [tabStatus, setTabStatus] = useState("all");

  const TABLE_HEAD = [
    { id: "created_at", label: "Date", align: "left" },
    { id: "batch_id", label: "Batch ID", align: "left" },
    { id: "requestor_id", label: "Requestor", align: "left" },
    { id: "total_subscription_adds", label: "Total Subscription Adds", align: "left" },
    { id: "total_subscription_removals", label: "Total Subscription Removals", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ];

  const getLengthByStatus = (status) => batchRequestsDataForTabs.filter((item) => item.status === status).length;

  const TABS = [
    { value: "all", label: "All", color: "primary", count: batchRequestsDataForTabs.length },
    { value: "Processed", label: "Processed", color: "success", count: getLengthByStatus("Processed") },
    { value: "Failed", label: "Failed", color: "error", count: getLengthByStatus("Failed") },
    { value: "Pending", label: "Pending", color: "warning", count: getLengthByStatus("Pending") },
    { value: "Processing", label: "Processing", color: "primary", count: getLengthByStatus("Processing") },
    { value: "Expired", label: "Expired", color: "info", count: getLengthByStatus("Expired") },
  ];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  const [search, setSearch] = useState("");

  useEffect(() => {
    const filteredBatches = batchRequestsData?.filter((batch) => {
      const isStatusMatch =
        tabStatus === "all" ||
        (tabStatus === "Processed" && batch?.status === "Processed") ||
        (tabStatus === "Failed" && batch?.status === "Failed") ||
        (tabStatus === "Pending" && batch?.status === "Pending") ||
        (tabStatus === "Expired" && batch?.status === "Expired") ||
        (tabStatus === "Processing" && batch?.status === "Processing");

      const isSearchMatch = batch.batch_id.toString().includes(search.toString());

      return isStatusMatch && isSearchMatch;
    });
    setBatchRequests([...filteredBatches]);
  }, [search, tabStatus]);

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleViewRow = useCallback(
    (id) => {
      navigate(PATH_DASHBOARD.subscriptions.viewSubscriptionRequests(id));
    },
    [navigate]
  );

  useEffect(() => {
    setBatchRequests(batchData);
    setBatchRequestsData(batchData);
    setBatchRequestsDataForTabs(batchData);
  }, [batchData]);

  const perfromUpdateBatches = async () => {
    setIsLoading(true);
    try {
      const response = await getBatchRequests(i_customer);
      if (response.status_code === 1001) {
        setBatchRequests(response.data);
        setBatchRequestsData(response.data);
      }
    } catch (error) {
      console.error("Error fetching distributors:", error);
    }
    setIsLoading(false);
  };

  const handleTabStatus = (event, newValue) => {
    setTabStatus(newValue);
  };

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.05)",
          zIndex: 9999,
        }}
      >
        <LoadingScreen />
      </Box>
    );

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          value={tabStatus}
          onChange={handleTabStatus}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <Label color={tab.color} sx={{ mr: 1 }}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Divider />
        <Grid container spacing={2}>
          <Grid item sx={{ m: 2 }} xs={12}>
            <TextField
              fullWidth
              value={search}
              placeholder="Search..."
              onChange={onSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={batchRequests.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {batchRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((batch, index) => (
                  <BatchRequestTableRow key={index} row={batch} onViewRow={() => handleViewRow(batch.id)} updateBatchData={() => perfromUpdateBatches()} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, batchRequests.length)} />
                <TableNoData isNotFound={batchRequests.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={batchRequests.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
