import { Button, Container, Grid } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
import { useSnackbar } from "src/components/snackbar";
import EscalationList from "src/sections/@dashboard/escalations/EscalationList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const EscalationPage = () => {
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [escalationList, setEscalationList] = useState([]);

  const createNewEscalation = () => {
    navigate(PATH_DASHBOARD.customerCare.createEscalation);
  };

  return (
    <>
      <Helmet>
        <title>Support Ticket Escalation | megsApp</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="Support Ticket Escalation"
              links={[{ name: "Support Ticket Escalation", href: PATH_DASHBOARD.customerCare.escalation }, { name: "Escalation List" }]}
              action={
                <Button variant="contained" onClick={createNewEscalation} startIcon={<Iconify icon={"eva:plus-fill"} />}>
                  Add Escalation
                </Button>
              }
            />
          </Grid>
        </Grid>

        <EscalationList />
      </Container>
    </>
  );
};

export default EscalationPage;
