import * as Sentry from "@sentry/react";
import { axiosApiInstance } from "./axios";
import { axiosAuthInstance } from "./axiosAuth";

const base_url = process.env.REACT_APP_API_ENDPOINT;

export const getDistributors = async () => {
  try {
    const url = `${base_url}/app/distributor`;
    const { data } = await axiosAuthInstance.get(url);
    console.log(data);
    return data;
  } catch (ex) {
    console.log(ex);
    return handleErrorResponse(ex);
  }
};

export const getDistributorStores = async (distributor_id) => {
  try {
    const { data } = await axiosApiInstance.post("/app/monday/distribution/stores", {
      i_customer: distributor_id,
    });
    // console.log(data.data);
    return data;
  } catch (ex) {
    console.log(ex);
    return handleErrorResponse(ex);
  }
};

export const getActivations = async (distributor_i_customer) => {
  try {
    const url = `${base_url}/app/activation?distributor_i_customer=${distributor_i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    console.log(data);
    return data;
  } catch (ex) {
    console.log(ex);
    return handleErrorResponse(ex);
  }
};

export const validateBarcode = async (barcode, method) => {
  try {
    const url = `${base_url}/app/barcode/${barcode}?method=${method}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.log(ex);
    return handleErrorResponse(ex);
  }
};

export const assignBarcode = async (i_customer, activation_id, barcode, external_id, method) => {
  try {
    const url = `${base_url}/app/barcode/${barcode}`;
    const { data } = await axiosAuthInstance.put(url, {
      method: method,
      i_customer: i_customer,
      activation_id: activation_id,
      external_id: external_id,
    });
    return data;
  } catch (ex) {
    console.log(ex);
    return handleErrorResponse(ex);
  }
};

export const assignBarcodeBySerialNumberRange = async (i_customer, activation_id, external_id, method, start_serial_number, end_serial_number) => {
  try {
    const url = `${base_url}/app/simcard/range`;
    const { data } = await axiosAuthInstance.put(url, {
      method: method,
      i_customer: i_customer,
      activation_id: activation_id,
      external_id: external_id,
      starting_serial_number: start_serial_number,
      ending_serial_number: end_serial_number,
    });
    return data;
  } catch (ex) {
    console.log(ex.response.data);
    return handleErrorResponse(ex);
  }
};

export const scanOutItem = async (i_customer, activation_id, list, external_id) => {
  try {
    let post_data = {
      i_customer: i_customer,
      activation_id: activation_id,
      list: list,
      external_id: external_id,
    };
    console.log("post_data", post_data);

    const data = await axiosApiInstance.post(`/app/monday/distribution/sim/scan`, post_data);
    return data;
  } catch (ex) {
    console.error(ex);
    return handleErrorResponse(ex);
  }
};
function handleErrorResponse(ex) {
  Sentry.captureException(ex);

  const error_response = {
    data: {
      status_code: 9000,
      message: "An error occurred. Please try again later.",
    },
  };
  return error_response;
}
