import { Navigate, useRoutes } from "react-router-dom";
// auth
import RoleBasedGuard from "src/auth/RoleBasedGuard";
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
import MainLayout from "../layouts/main";
import SimpleLayout from "../layouts/simple";
// config
import { PATH_AFTER_LOGIN } from "../config-global";

//user roles
import { useAuthContext } from "src/auth/useAuthContext";
//
import {
  AboutPage,
  AccountSelectPage,
  AdminLoginPage,
  AdminUsersPage,
  //
  BlankPage,
  BlogNewPostPage,
  BlogPostPage,
  // Dashboard: Blog
  BlogPostsPage,
  CalendarPage,
  // Dashboard: App
  ChatPage,
  ComingSoonPage,
  //
  ComponentsOverviewPage,
  ConfirmationPage,
  Contact,
  CreateMessagingDetailsPage,
  CreateNoticesDetailsPage,
  DashboardGraphsPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMarkdownPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DistributorDashboardPage,
  // Dashboard: Sim Scan Out
  // SimDetailsPage,
  // Dashboard: Distributor Sim List
  DistributorSimCardsPage,
  // Dashboard: Distributors
  DistributorTransactionsPage,
  EcommerceCheckoutPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  EcommerceProductEditPage,
  EcommerceProductListPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  FaqsPage,
  // Dashboard: FileManager
  FileManagerPage,
  ForgotPasswordPage,
  FoundationColorsPage,
  FoundationGridPage,
  FoundationIconsPage,
  FoundationShadowsPage,
  FoundationTypographyPage,
  GeneralAnalyticsPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralFilePage,
  GraphsPage,
  HoneySalesReportPage,
  InvoiceCreatePage,
  InvoiceDetailsPage,
  InvoiceEditPage,
  // Dashboard: Invoice
  InvoiceListPage,
  IotDetailsPage,
  // Dashboard: IOT
  IotListPage,
  IotUsagePage,
  KanbanPage,
  LeadsPage,
  LoginPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  MailPage,
  MaintenancePage,
  MessagingDetailsPage,
  // Messaging
  MessagingListPage,
  NoticesDetailsPage,
  //  Dashboard: Notices
  NoticesListPage,
  // SIM4Schools Onboarding Form
  OnboardingPage,
  Page403,
  Page404,
  //
  Page500,
  PaymentPage,
  PermissionDeniedPage,
  PortDetailsPage,
  // Dashboard: Porting
  PortInListPage,
  PortInStartPage,
  PricingPage,
  ReportsDetailsPage,
  // Reports
  ReportsListPage,
  ResellerSellProductPage,
  // Dashboard: Resellers
  ResellerTransactionsPage,
  ResetPasswordPage,
  RicaDetailsPage,
  RicaListPage,
  SelfRicaDetailsPage,
  SelfRicaFormPage,
  // Dashboard: Self Rica
  SelfRicaListPage,
  SimAssignmentPage,
  SimOrdersCreatePage,
  SimOrdersListPage,
  SimScanOutPage,
  SimStatusPage,
  SimSwapPage,
  SingleSignOn,
  TopUpPage,
  UserAccountPage,
  UserCardsPage,
  UserCreatePage,
  UserEditPage,
  // Dashboard: User
  UserListPage,
  UserManagementPage,
  UserProfilePage,
  // Dashboard: Vouchers
  VoucherDetailsPage,
  VoucherListPage,
  BulkPaymentsPage,
  SubscriptionsPage,
  ActiveSubscriptionsPage,
  ActiveSubscriptionsDetailsPage,
  DistributorNoticesPage,
  CreateDistributorNoticesDetailsPage,
  DistributorNoticeListPage,
  TupperwareOnboardingPage,
  LMAOnboardingPage,
} from "./elements";
import AppUsersPage from "src/pages/dashboard/AppUsersPage";
import DistributorReportPage from "src/pages/dashboard/DistributorReportPage";
import DistributorNoticesDetailsPage from "src/pages/dashboard/DistributorNoticesDetailsPage";
import ProductsPage from "src/pages/dashboard/ProductsPage";
import ProductGroupPage from "src/pages/dashboard/ProductGroupPage";
import ProductDetailsPage from "src/pages/dashboard/ProductDetailsPage";
import ProductGroupDetailsPage from "src/pages/dashboard/ProductGroupDetailsPage";
import EscalationDetailsPage from "src/pages/dashboard/EscalationDetailsPage";
import EscalationPage from "src/pages/dashboard/EscalationPage";
import CreateEscalationsPage from "src/pages/dashboard/CreateEscalationsPage";
import ManageWebHooksPage from "src/pages/dashboard/ManageWebHooksPage";
import BatchRequestPage from "src/pages/dashboard/BatchRequestPage";
import BatchDetailsPage from "src/pages/dashboard/BatchDetailsPage";
import AgentVideosPage from "src/pages/dashboard/AgentVideosPage";
import ConsumerVideosPage from "src/pages/dashboard/ConsumerVideosPage";
import UploadTrainingVideosPage from "src/pages/dashboard/UploadTrainingVideosPage";
import TrainingVideosPage from "src/pages/dashboard/TrainingVideosPage";
import EditTrainingVideos from "src/sections/@dashboard/agent-videos/EditTrainingVideos";
import UnsubscribeMarketing from "src/pages/dashboard/UnsubscribeMarketing";
import MetabaseGraphPage from "src/pages/dashboard/MetabaseGraphPage";

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext();
  console.log("user", user);
  return useRoutes([
    // Root redirect to /dashboard/app if logged in
    // else redirect to /auth/login-admin
    // /sim4schools needs no login
    {
      path: "/sim4schools",
      element: <SimpleLayout />,
      children: [
        {
          path: "onboarding",
          element: <OnboardingPage />,
        },
        {
          path: "confirmation",
          element: <ConfirmationPage />,
        },
      ],
    },
    {
      path: "/tupperware",
      element: <SimpleLayout />,
      children: [
        {
          path: "onboarding",
          element: <TupperwareOnboardingPage />,
        },
        // {
        //   path: "confirmation",
        //   element: <ConfirmationPage />,
        // },
      ],
    },
    {
      path: "/lma",
      element: <SimpleLayout />,
      children: [
        {
          path: "onboarding",
          element: <LMAOnboardingPage />,
        },
        // {
        //   path: "confirmation",
        //   element: <ConfirmationPage />,
        // },
      ],
    },
    {
      path: "/leads",
      element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: <LeadsPage />,
        },
      ],
    },
    {
      path: "/graphs",
      element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: <GraphsPage />,
        },
      ],
    },
    {
      path: "/graphs2",
      element: <SimpleLayout />,
      children: [
        {
          path: "",
          element: <GeneralBookingPage />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <Navigate
            to={
              user?.type !== "iot" && user?.type !== "sub-distributor"
                ? PATH_AFTER_LOGIN
                : user?.type === "iot"
                  ? "/dashboard/iot/list"
                  : "/dashboard/rica/form/agent_rica"
            }
          />
        </AuthGuard>
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
        },
      ],
    },
    // Auth
    {
      path: "auth",
      children: [
        {
          path: "account-select",
          element: (
            <GuestGuard>
              <AccountSelectPage />
            </GuestGuard>
          ),
        },
        {
          path: "login-distributor",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "login-admin",
          element: (
            <GuestGuard>
              <AdminLoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "sso",
          element: <SingleSignOn />,
        },
        {
          path: "forgot-password",
          element: (
            <GuestGuard>
              <ForgotPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Page
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={
                user?.type !== "iot" && user?.type !== "sub-distributor"
                  ? PATH_AFTER_LOGIN
                  : user?.type === "iot"
                    ? "/dashboard/iot/list"
                    : "/dashboard/rica/form/agent_rica"
              }
              replace
            />
          ),
          index: true,
        },
        { path: "app", element: <GeneralAppPage /> },
        { path: "ecommerce", element: <GeneralEcommercePage /> },
        { path: "analytics", element: <GeneralAnalyticsPage /> },
        { path: "banking", element: <GeneralBankingPage /> },
        { path: "booking", element: <GeneralBookingPage /> },
        { path: "file", element: <GeneralFilePage /> },
        {
          path: "admin",
          children: [
            {
              element: <Navigate to="/dashboard/admin/admin-users" replace />,
              index: true,
            },
            {
              path: "admin-users",
              children: [
                {
                  element: <Navigate to="/dashboard/admin/admin-users/list" replace />,
                  index: true,
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <AdminUsersPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "app-users",
              children: [
                {
                  element: <Navigate to="/dashboard/admin/app-users/list" replace />,
                  index: true,
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor"]}>
                      <AppUsersPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "distributor-notices",
              children: [
                {
                  element: <Navigate to="/dashboard/admin/distributor-notices/list" replace />,
                  index: true,
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor"]}>
                      <DistributorNoticeListPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor"]}>
                      <CreateDistributorNoticesDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor"]}>
                      <DistributorNoticesDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "customer-care",
          children: [
            {
              path: "self-rica",
              children: [
                {
                  element: <Navigate to="/dashboard/customer-care/self-rica/list" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <SelfRicaDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <SelfRicaListPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "sim-swap",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <SimSwapPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "topup",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <TopUpPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "porting",
              children: [
                {
                  element: <Navigate to="/porting/port-in" replace />,
                  index: true,
                },
                {
                  path: "port-in",
                  element: (
                    <RoleBasedGuard roles={["admin", "support_agent"]}>
                      <PortInListPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":msisdn",
                  element: (
                    <RoleBasedGuard roles={["admin", "support_agent"]}>
                      <PortDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "escalation",
              children: [
                {
                  element: <Navigate to="/customer-care/escalation/list" replace />,
                  index: true,
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor", "sub-distributor", "support_agent"]}>
                      <EscalationPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor", "sub-distributor", "support_agent"]}>
                      <EscalationDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <RoleBasedGuard roles={["distributor", "sub-distributor", "support_agent"]}>
                      <CreateEscalationsPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "unsubscribe",
              children: [
                {
                  element: <Navigate to="/unsubscribe/marketing" replace />,
                  index: true,
                },
                {
                  path: "marketing",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <UnsubscribeMarketing />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "porting",
          children: [
            {
              element: <Navigate to="/dashboard/porting/port-in" replace />,
              index: true,
            },
            {
              path: "port-in",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <PortInListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":msisdn",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <PortDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "wallets",
          children: [
            {
              element: <Navigate to="/dashboard/wallets/bulk-payments" replace />,
              index: true,
            },
            {
              path: "bulk-payments",
              element: (
                <RoleBasedGuard roles={["distributor"]}>
                  <BulkPaymentsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "subscriptions",
          children: [
            {
              element: <Navigate to="/dashboard/subscriptions/manage-subscriptions" replace />,
              index: true,
            },
            {
              path: "manage-subscriptions",
              element: (
                <RoleBasedGuard roles={["distributor"]}>
                  <SubscriptionsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "subscription-requests",
              children: [
                {
                  element: <Navigate to="/dashboard/subscriptions/subscription-requests/list" replace />,
                  index: true,
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["distributor"]}>
                      <BatchRequestPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["distributor"]}>
                      <BatchDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "active-subscriptions",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <ActiveSubscriptionsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <ActiveSubscriptionsDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "products",
          children: [
            {
              element: <Navigate to="/dashboard/products/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <ProductsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <ProductDetailsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "product-group",
              children: [
                {
                  element: <Navigate to="/dashboard/products/product-group/list" replace />,
                  index: true,
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <ProductGroupPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <ProductGroupDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },

        {
          path: "rica",
          children: [
            {
              element: <Navigate to="/dashboard/rica/list" replace />,
              index: true,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <RicaListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "self-rica",
              children: [
                {
                  element: <Navigate to="/dashboard/rica/self-rica/list" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <SelfRicaDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <SelfRicaListPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "form/:identifier",
              element: (
                <RoleBasedGuard roles={["admin", "distributor", "sub-distributor", "honey", "support_agent"]}>
                  <SelfRicaFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "port",
              element: (
                <RoleBasedGuard roles={["admin", "distributor", "sub-distributor", "honey", "support_agent"]}>
                  <PortInStartPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "sim-swap",
          element: (
            <RoleBasedGuard roles={["admin"]}>
              <SimSwapPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: "topup",
          element: (
            <RoleBasedGuard roles={["admin"]}>
              <TopUpPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: "user-management",
          element: (
            <RoleBasedGuard roles={["admin"]}>
              <UserManagementPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: "messaging",
          children: [
            {
              element: <Navigate to="/dashboard/messaging/list" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <MessagingDetailsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <MessagingListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <CreateMessagingDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "reports",
          children: [
            {
              element: <Navigate to="/dashboard/reports/list" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <ReportsDetailsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <ReportsListPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "iot",
          children: [
            {
              element: <Navigate to="/dashboard/iot/list" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin", "iot"]}>
                  <IotDetailsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin", "iot"]}>
                  <IotListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "usage",
              element: (
                <RoleBasedGuard roles={["admin", "iot"]}>
                  <IotUsagePage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "sim-cards",
          children: [
            {
              element: <Navigate to="/dashboard/sim-cards/scan-out" replace />,
              index: true,
            },
            {
              path: "scan-out",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <SimScanOutPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "assignment",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <SimAssignmentPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "status",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <SimStatusPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "orders",
              children: [
                {
                  element: <Navigate to="/dashboard/sim-cards/orders/create" replace />,
                  index: true,
                },
                {
                  path: "create",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor"]}>
                      <SimOrdersCreatePage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "sim-scanout",
          element: (
            <RoleBasedGuard roles={["admin", "distributor"]}>
              <SimScanOutPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: "sim-assignment",
          element: (
            <RoleBasedGuard roles={["admin", "distributor"]}>
              <SimAssignmentPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: "sim-orders",
          children: [
            {
              element: <Navigate to="/dashboard/sim-orders/list" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <SimOrdersListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <SimOrdersListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <SimOrdersCreatePage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "notices",
          children: [
            {
              element: <Navigate to="/dashboard/notices/list" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <NoticesDetailsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <NoticesListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard roles={["admin"]}>
                  <CreateNoticesDetailsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "cx",
          children: [
            {
              path: "messaging",
              children: [
                {
                  element: <Navigate to="/dashboard/cx/messaging/list" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <MessagingDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <MessagingListPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <CreateMessagingDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "notices",
              children: [
                {
                  element: <Navigate to="/dashboard/cx/notices/list" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <NoticesDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <NoticesListPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <RoleBasedGuard roles={["admin"]}>
                      <CreateNoticesDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "distributors",
          children: [
            {
              element: <Navigate to="/dashboard/distributors/transactions" replace />,
              index: true,
            },
            {
              path: "transactions",
              element: (
                <RoleBasedGuard roles={["distributor", "support_agent"]}>
                  <DistributorTransactionsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "dashboard",
              element: <DistributorDashboardPage />,
            },
            {
              path: "graphs",
              element: (
                <RoleBasedGuard roles={["distributor"]}>
                  <DashboardGraphsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "sim-cards",
              element: (
                <RoleBasedGuard roles={["admin", "distributor", "support_agent"]}>
                  <DistributorSimCardsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "status-list",
              children: [
                {
                  element: <Navigate to="/dashboard/distributors/status-list/list" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor", "support_agent"]}>
                      <RicaDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin", "distributor", "support_agent"]}>
                      <RicaListPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "sales-report",
              element: (
                <RoleBasedGuard roles={["honey"]}>
                  <HoneySalesReportPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "distributor-report",
              element: (
                <RoleBasedGuard roles={["admin", "distributor"]}>
                  <DistributorReportPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "vouchers",
              children: [
                {
                  element: <Navigate to="/dashboard/vouchers/list" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <RoleBasedGuard roles={["admin", "support_agent"]}>
                      <VoucherDetailsPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard roles={["admin", "support_agent"]}>
                      <VoucherListPage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "resellers",
          children: [
            {
              element: <Navigate to="/dashboard/resellers/transactions" replace />,
              index: true,
            },
            {
              path: "transactions",
              element: (
                <RoleBasedGuard roles={["distributor", "reseller"]}>
                  <ResellerTransactionsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "sell-product",
              element: (
                <RoleBasedGuard roles={["distributor", "reseller"]}>
                  <ResellerSellProductPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "vouchers",
          children: [
            {
              element: <Navigate to="/dashboard/vouchers/list" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <RoleBasedGuard roles={["admin", "support_agent"]}>
                  <VoucherDetailsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard roles={["admin", "support_agent"]}>
                  <VoucherListPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/shop" replace />,
              index: true,
            },
            { path: "shop", element: <EcommerceShopPage /> },
            { path: "product/:name", element: <EcommerceProductDetailsPage /> },
            { path: "list", element: <EcommerceProductListPage /> },
            { path: "product/new", element: <EcommerceProductCreatePage /> },
            {
              path: "product/:name/edit",
              element: <EcommerceProductEditPage />,
            },
            { path: "checkout", element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfilePage /> },
            { path: "cards", element: <UserCardsPage /> },
            { path: "list", element: <UserListPage /> },
            { path: "new", element: <UserCreatePage /> },
            { path: ":name/edit", element: <UserEditPage /> },
            { path: "account", element: <UserAccountPage /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceListPage /> },
            { path: ":id", element: <InvoiceDetailsPage /> },
            { path: ":id/edit", element: <InvoiceEditPage /> },
            { path: "new", element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: "webhooks",
          children: [
            {
              element: <Navigate to="/dashboard/webhooks/manage" replace />,
              index: true,
            },
            { path: "manage", element: <ManageWebHooksPage /> },
          ],
        },
        {
          path: "metabase",
          children: [
            {
              element: <Navigate to="/dashboard/metabase/graph" replace />,
              index: true,
            },
            { path: "graph", element: <MetabaseGraphPage /> },
          ],
        },
        {
          path: "training-videos",
          children: [
            {
              element: <Navigate to="/dashboard/training-videos/agent-videos" replace />,
              index: true,
            },
            { path: "list", element: <TrainingVideosPage /> },
            { path: ":category/:id", element: <UploadTrainingVideosPage /> },
            { path: "add", element: <UploadTrainingVideosPage /> },
            { path: "agent-videos", element: <AgentVideosPage /> },
            { path: "consumer-videos", element: <ConsumerVideosPage /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPostsPage /> },
            { path: "post/:title", element: <BlogPostPage /> },
            { path: "new", element: <BlogNewPostPage /> },
          ],
        },
        { path: "files-manager", element: <FileManagerPage /> },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <MailPage /> },
            { path: "label/:customLabel/:mailId", element: <MailPage /> },
            { path: ":systemLabel", element: <MailPage /> },
            { path: ":systemLabel/:mailId", element: <MailPage /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <ChatPage />, index: true },
            { path: "new", element: <ChatPage /> },
            { path: ":conversationKey", element: <ChatPage /> },
          ],
        },
        { path: "calendar", element: <CalendarPage /> },
        { path: "kanban", element: <KanbanPage /> },
        { path: "permission-denied", element: <PermissionDeniedPage /> },
        { path: "blank", element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/auth/account-select" replace /> },
        { path: "about-us", element: <AboutPage /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <FaqsPage /> },
        // Demo Components
        {
          path: "components",
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: "foundation",
              children: [
                {
                  element: <Navigate to="/components/foundation/colors" replace />,
                  index: true,
                },
                { path: "colors", element: <FoundationColorsPage /> },
                { path: "typography", element: <FoundationTypographyPage /> },
                { path: "shadows", element: <FoundationShadowsPage /> },
                { path: "grid", element: <FoundationGridPage /> },
                { path: "icons", element: <FoundationIconsPage /> },
              ],
            },
            {
              path: "mui",
              children: [
                {
                  element: <Navigate to="/components/mui/accordion" replace />,
                  index: true,
                },
                { path: "accordion", element: <MUIAccordionPage /> },
                { path: "alert", element: <MUIAlertPage /> },
                { path: "autocomplete", element: <MUIAutocompletePage /> },
                { path: "avatar", element: <MUIAvatarPage /> },
                { path: "badge", element: <MUIBadgePage /> },
                { path: "breadcrumbs", element: <MUIBreadcrumbsPage /> },
                { path: "buttons", element: <MUIButtonsPage /> },
                { path: "checkbox", element: <MUICheckboxPage /> },
                { path: "chip", element: <MUIChipPage /> },
                { path: "data-grid", element: <MUIDataGridPage /> },
                { path: "dialog", element: <MUIDialogPage /> },
                { path: "list", element: <MUIListPage /> },
                { path: "menu", element: <MUIMenuPage /> },
                { path: "pagination", element: <MUIPaginationPage /> },
                { path: "pickers", element: <MUIPickersPage /> },
                { path: "popover", element: <MUIPopoverPage /> },
                { path: "progress", element: <MUIProgressPage /> },
                { path: "radio-button", element: <MUIRadioButtonsPage /> },
                { path: "rating", element: <MUIRatingPage /> },
                { path: "slider", element: <MUISliderPage /> },
                { path: "stepper", element: <MUIStepperPage /> },
                { path: "switch", element: <MUISwitchPage /> },
                { path: "table", element: <MUITablePage /> },
                { path: "tabs", element: <MUITabsPage /> },
                { path: "textfield", element: <MUITextFieldPage /> },
                { path: "timeline", element: <MUITimelinePage /> },
                { path: "tooltip", element: <MUITooltipPage /> },
                { path: "transfer-list", element: <MUITransferListPage /> },
                { path: "tree-view", element: <MUITreesViewPage /> },
              ],
            },
            {
              path: "extra",
              children: [
                {
                  element: <Navigate to="/components/extra/animate" replace />,
                  index: true,
                },
                { path: "animate", element: <DemoAnimatePage /> },
                { path: "carousel", element: <DemoCarouselsPage /> },
                { path: "chart", element: <DemoChartsPage /> },
                {
                  path: "copy-to-clipboard",
                  element: <DemoCopyToClipboardPage />,
                },
                { path: "editor", element: <DemoEditorPage /> },
                {
                  path: "form-validation",
                  element: <DemoFormValidationPage />,
                },
                { path: "image", element: <DemoImagePage /> },
                { path: "label", element: <DemoLabelPage /> },
                { path: "lightbox", element: <DemoLightboxPage /> },
                { path: "map", element: <DemoMapPage /> },
                { path: "mega-menu", element: <DemoMegaMenuPage /> },
                { path: "multi-language", element: <DemoMultiLanguagePage /> },
                { path: "navigation-bar", element: <DemoNavigationBarPage /> },
                {
                  path: "organization-chart",
                  element: <DemoOrganizationalChartPage />,
                },
                { path: "scroll", element: <DemoScrollbarPage /> },
                { path: "snackbar", element: <DemoSnackbarPage /> },
                { path: "text-max-line", element: <DemoTextMaxLinePage /> },
                { path: "upload", element: <DemoUploadPage /> },
                { path: "markdown", element: <DemoMarkdownPage /> },
              ],
            },
          ],
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: "pricing", element: <PricingPage /> },
        { path: "payment", element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoonPage /> },
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
