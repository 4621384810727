import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
// @mui
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
// sections
import { getProductDetails, listAllProductGroup } from "src/api/megs-api-api";
import ProductDetails from "src/sections/@dashboard/products/details";

// ----------------------------------------------------------------------

export default function ProductDetailsPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();
  const [productData, setProductData] = useState("");
  const [productGroups, setProductGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchProductDetails();
      await fetchProductGroups();
      setLoading(false);
    })();
  }, []);

  const fetchProductDetails = async () => {
    const { data } = await getProductDetails(id);
    setProductData(data);
  };
  const fetchProductGroups = async () => {
    const { data } = await listAllProductGroup();
    setProductGroups(data);
  };

  return (
    <>
      <Helmet>
        <title> Rica Details | megsApp Portal</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Product Details"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "Product List",
              href: PATH_DASHBOARD.products.productList,
            },
            { name: `${id}` },
          ]}
        />

        {productData && <ProductDetails productData={productData} productGroups={productGroups} productId={id} />}
      </Container>
    </>
  );
}
