import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import CreateNoticeDetails from "src/sections/@dashboard/notices/create"; // Adjust path as necessary
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
import { PATH_DASHBOARD } from "../../routes/paths";
import CreateEscalationDetails from "src/sections/@dashboard/escalations/create";

export default function CreateEscalationsPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title>New Escalation | megsApp Portal</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Support Ticket Escalation"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            {
              name: "New Escalation",
              href: PATH_DASHBOARD.customerCare.root,
            },
          ]}
        />
        <CreateEscalationDetails />
      </Container>
    </>
  );
}
