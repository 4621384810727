import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
import { getBatchDetails } from "src/api/megs-api-api";
import ActiveSubscriptionsDetailsList from "src/sections/@dashboard/subscriptions/ActiveSubscriptionsDetailsList";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import BatchDetailsList from "src/sections/@dashboard/subscriptions/BatchDetailsList";

// ----------------------------------------------------------------------

export default function BatchDetailsPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();
  const [batchDetailsData, setBatchDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchBatchDetails();
      setLoading(false);
    })();
  }, []);

  const fetchBatchDetails = async () => {
    const { data } = await getBatchDetails(id);
    setBatchDetailsData(data);
  };

  return (
    <>
      {loading ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <>
          <Helmet>
            <title> Batch Requests | megsApp Portal</title>
          </Helmet>

          <Container maxWidth={themeStretch ? false : "xl"}>
            <CustomBreadcrumbs
              heading="Batch Requests"
              links={[
                { name: "Dashboard", href: PATH_DASHBOARD.root },
                {
                  name: "Batch Requests",
                  href: PATH_DASHBOARD.subscriptions.subscriptionRequests,
                },
                { name: `${id}` },
              ]}
            />

            <BatchDetailsList batchDetailsData={batchDetailsData} />
          </Container>
        </>
      )}
    </>
  );
}
