import React, { useState } from "react";
import {
  Card,
  Grid,
  Typography,
} from "@mui/material";
import { FormProvider, RHFSelect, RHFTextField } from "src/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { useSnackbar } from "src/components/snackbar";

import LoadingButton from "@mui/lab/LoadingButton";
import { reInitiatePortInStart } from "src/api/soa-api";
import CustomDialog from "src/components/dialog/CustomDialog";


import PropTypes from "prop-types";
import { useAuthContext } from "src/auth/useAuthContext";
import { useSelector } from "react-redux";


const ReInitiatePortIn = ({ customer, disabled, openDialog, getPortHistory, setPortHistory }) => {

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();
  
  const selectedRole = useSelector((state) => state.auth.selectedRole);




  const [selectedValue, setSelectedValue] = useState("no");
  const [openReinitiatePort, setOpenReinitiatePort] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    action: "",
    handleClose: null,
  });

  const handleCloseDialog = async (value) => {
    if (dialog.handleClose) {
      await dialog.handleClose(value);
    }
    setDialog({ ...dialog, open: false });
  };

  const handleReinitiatePortClose = async (value) => {
    setOpenReinitiatePort(false);
    if (value === "yes") {
      console.log("Reinitiating Port...");
      const response = await reInitiatePortInStart(
        values.msisdn,
        values.iccid,
        values.network,
        customer.whatsapp_msisdn,
        user.username
      );
      if (response.statusCode === "0") {
        enqueueSnackbar("Reinitiated Port", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        enqueueSnackbar(`Reinitiated Port failed: ${response.statusMessage}`, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
      const history = await getPortHistory(customer.msisdn);
      setPortHistory(history);
      reset(defaultValues);
    } else {
      console.log("Reinitiate Port cancelled");
    }
  };


  // React Hook Form and Yup
  const PortReInitiateSchema = Yup.object().shape({
    msisdn: Yup.string().required("Msisdn is required"),
    iccid: Yup.string().required("ICCID is required"),
    network: Yup.string().required("Network is required"),
  });

  const defaultValues = {
    msisdn: customer?.msisdn || "",
    iccid: customer?.sim_serial_number || "",
    network: customer?.port_network || "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(PortReInitiateSchema),
    mode: "onBlur",
  });

  const {
    formState: { isSubmitting },
    reset,
  } = methods;

  const values = methods.watch();

  return (
    <Grid item xs={12} md={12}>
      <Card sx={{ py: 3, px: 3, height: "100%" }}>
        {customer && (
          <>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: "text.disabled" }}
            >
              Re-Initiate Port In
            </Typography>

            <FormProvider methods={methods}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    name="msisdn"
                    label="MSISDN"
                    fullWidth
                    disabled={disabled || selectedRole.role === "support_agent"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    name="iccid"
                    label="ICCID"
                    fullWidth
                    disabled={disabled || selectedRole.role === "support_agent"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFSelect
                    name="network"
                    label="Network"
                    fullWidth
                    disabled={disabled || selectedRole.role === "support_agent"}
                  >
                    {/* <option value="" disabled /> */}
                    <option value="MTNSP">MTN</option>
                    <option value="VODACOM">Vodacom</option>
                    <option value="TELKMOBL">Telkom</option>
                    <option value="CELLC">Cell C</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12} md={6}>
                  <LoadingButton
                    onClick={() => openDialog("re-initiate", handleReinitiatePortClose)}
                    color={"primary"}
                    variant="contained"
                    loading={isSubmitting}
                    fullWidth
                    sx={{
                      height: "100%",
                    }}
                    disabled={disabled}
                  >
                    Re-Initiate Port In Start
                  </LoadingButton>
                </Grid>
              </Grid>
            </FormProvider>
          </>
        )}
      </Card>
      <CustomDialog
        selectedValue={selectedValue}
        open={dialog.open}
        action={dialog.action}
        onClose={handleCloseDialog}
      />
    </Grid>
  );
};

ReInitiatePortIn.propTypes = {
  customer: PropTypes.object,
  disabled: PropTypes.bool,
  openDialog: PropTypes.func,
  getPortHistory: PropTypes.func,
  setPortHistory: PropTypes.func,
};

export default ReInitiatePortIn;
