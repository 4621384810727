import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import Quill from "react-quill";
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField } from "src/components/hook-form";
import { useForm } from "react-hook-form";
import { toggleNoticeStatus, updateNoticeDetails, getNoticeTypes } from "src/api/notices-api";
import { useSnackbar } from "../../../../components/snackbar";

NoticeDetails.propTypes = {
  notice: propTypes.object.isRequired,
  setNotice: propTypes.func.isRequired,
  brandData: propTypes.array.isRequired,
};

function NoticeDetails({ notice, setNotice, brandData }) {
  const [noticeState, setNoticeState] = useState(notice);
  const [noticeTypes, setNoticeTypes] = useState([]);
  const messageEditorRef = useRef();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // console.log("Notice: ", notice)



  // console.log("NoticeDetails: ", noticeState);

  // Update local state when notice prop changes
  useEffect(() => {
    setNoticeState(notice);
  }, [notice]);

  useEffect(() => {
    const fetchNoticeTypes = async () => {
      try {
        const response = await getNoticeTypes();
        if (response.status_code === 1001) {
          setNoticeTypes(response.data);
        }

      } catch (error) {
        console.error("error: ", error);
      } 
    };
    fetchNoticeTypes();
  }, []);

  const handleNoticeTypeIdChange = (event) => {
    setNoticeState({
      ...noticeState,
      notice_type_id: event.target.value,
    });
  };

  // Handler to update local state on input change
  const handleInputChange = (event) => {
    setNoticeState({
      ...noticeState,
      [event.target.name]: event.target.value,
    });
  };

  const handleBrandChange = (event, value) => {
    // console.log("handleBrandChange: ", value);
    if (value) {
      setNoticeState({
        ...noticeState,
        brand_id: value.value,
      });
    }
  }

  // Handler to update local state on switch toggle
  const handleSwitchChange = async (event) => {
    const newStatus = event.target.checked ? 1 : 0;
    setNoticeState({
      ...noticeState,
      [event.target.name]: newStatus,
    });

    // Call the API to update the status
    const response = await toggleNoticeStatus(noticeState.id);

    // If the API call fails, revert the UI change
    if (response.status_code !== 1001) {
      setNoticeState({
        ...noticeState,
        [event.target.name]: noticeState[event.target.name],
      });
      enqueueSnackbar("Unable to update status", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } else {
      enqueueSnackbar("Status updated successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  // Handler to update local state on editor change
  const handleMessageChange = (content_editor) => {
    if (messageEditorRef.current !== undefined) {
      const editor = messageEditorRef.current.getEditor();
      const content_raw = editor.getContents();
      const message_raw_content = content_raw.ops
        .map((raw) => raw.insert)
        .join("");
    }
  };

  const handleSaveChanges = async () => {
    if (messageEditorRef.current !== undefined) {
      const editor = messageEditorRef.current.getEditor();
      const content_raw = editor.getContents();
      const message_raw_content = content_raw.ops
        .map((raw) => raw.insert)
        .join("");

      setLoading(true);

      const result = await updateNoticeDetails({
        ...noticeState,
        message: message_raw_content,
      });

      if (result.status_code === 1001) {
        enqueueSnackbar("Notice updated successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        enqueueSnackbar("Unable to update notice", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }

      setLoading(false);
    }

    setNotice(noticeState);
  };

  const methods = useForm({
    defaultValues: {
      notice_type_id: noticeState.notice_type_id,
      message: noticeState.message,
      meta_data: noticeState.meta_data,
      brand_id: {
        label: brandData.find((brand) => brand.value === noticeState.brand_id).label,
        value: noticeState.brand_id,
      },
      active: noticeState.active,
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card xs={12} sx={{ py: 2, px: 3 }}>
          <Typography
            paragraph
            variant="overline"
            sx={{ color: "text.disabled", pb: 2 }}
          >
            Notice Details
          </Typography>
          <Box sx={{ maxWidth: "100%", pb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={1}>
                <TextField
                  disabled
                  variant="outlined"
                  fullWidth
                  label="ID"
                  name="id"
                  value={noticeState.id || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="notice-type-id-label">
                    Notice Type ID
                  </InputLabel>
                  <Select
                    labelId="notice-type-id-label"
                    id="notice-type-id"
                    value={noticeState.notice_type_id || ""}
                    onChange={handleNoticeTypeIdChange}
                    label="Notice Type ID"
                  >
                    {noticeTypes.map((noticeType) => (
                      <MenuItem key={noticeType.id} value={noticeType.id}>
                        {noticeType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormProvider
                  methods={methods}
                >
                  <RHFAutocomplete
                    name="brand_id"
                    label="Brand"
                    options={brandData}
                    onChange={handleBrandChange}
                  />
                </FormProvider>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Meta Data"
                  name="meta_data"
                  value={noticeState.meta_data || ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={noticeState.active === 1}
                      onChange={handleSwitchChange}
                      name="active"
                    />
                  }
                  label="Active"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={10}
                sx={{
                  mt: 2,
                }}
              >
                <Quill
                  ref={messageEditorRef}
                  value={noticeState.message}
                  onChange={handleMessageChange}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={handleSaveChanges}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<Save />}
                >
                  Save Changes
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NoticeDetails;
