import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Grid,
  Autocomplete,
  TextField,
  Divider,
  Tabs,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import ActiveSubscriptionsDetailsRow from "./ActiveSubscriptionsDetailsRow";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
export default function ActiveSubscriptionsDetailsList({ subscriptionData }) {
  const [subscriptionDetails, seSubscriptionDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // const [selectedDistributor, setSelectedDistributor] = useState(selectedRole.role === "admin" ? null : distributors[0]);

  // const handleDistributorChange = (event, newValue) => {
  //   console.log("Distributor Values", newValue);
  //   setSelectedDistributor(newValue);
  // };

  const TABLE_HEAD = [
    { id: "pro_rata", label: "Pro Rata", align: "left" },
    { id: "pro_rata_days", label: "Pro Rata Days", align: "left" },
    // { id: "role", label: "Role", align: "left" },
    { id: "pro_rata_percentage", label: "Pro Rata Percentage", align: "left" },
    { id: "amount", label: "Amount", align: "left" },
    { id: "data_value", label: "Data Value", align: "left" },
    { id: "airtime_value", label: "Airtime Value", align: "left" },
    { id: "billing_date", label: "Billing Date", align: "left" },
    { id: "next_billing_date", label: "Next Billing Date", align: "left" },
    { id: "billed_date", label: "Billed Date", align: "left" },
    { id: "status", label: "Status", align: "left" },
    { id: "retry_count", label: "Retry Count", align: "left" },
  ];

  const TABS = [{ value: "all", label: "All", color: "primary", count: subscriptionDetails.length }];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    seSubscriptionDetails(subscriptionData);
  }, [subscriptionData]);

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.05)",
          zIndex: 9999,
        }}
      >
        <LoadingScreen />
      </Box>
    );

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        />
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={subscriptionDetails.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {subscriptionDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((details, index) => (
                  <ActiveSubscriptionsDetailsRow key={index} row={details} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, subscriptionDetails.length)} />
                <TableNoData isNotFound={subscriptionDetails.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={subscriptionDetails.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
