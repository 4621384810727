import { Button, Container, Grid, TextField, Stack, Card, Box, CircularProgress } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSettingsContext } from "src/components/settings";
import { PATH_DASHBOARD } from "../../routes/paths";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";
import { useSelector } from "react-redux";
import { getMetabaseDashboardURL, getRedirectSVIXURL } from "src/api/megs-api-api";
import { useSnackbar } from "src/components/snackbar";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";

const MetabaseGraphPage = () => {
  const { themeStretch } = useSettingsContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [metabaseURL, setMetabaseURL] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectToMetabase() {
      setIsLoading(true);
      try {
        const response = await getMetabaseDashboardURL(10);
        if (response.status_code === 1001) {
          if (response.data) {
            setMetabaseURL(response.data);
            // window.open(response.data, "_blank");
          }
          // navigate(PATH_DASHBOARD.general.app);
        }
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
      setIsLoading(false);
    }

    redirectToMetabase();
  }, []);

  return (
    <>
      <Helmet>
        <title>Metabase | megsApp</title>
      </Helmet>

      {isLoading ? (
        <LoadingScreen />
      ) : (
        <iframe
          src={metabaseURL}
          style={{ width: "100%", height: "calc(100vh - 90px)", border: "none" }}
          allowtransparency="true"
          allow="clipboard-write"
          loading="lazy"
        />
      )}
    </>
  );
};

export default MetabaseGraphPage;
