import PropTypes from 'prop-types';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  console.log("window.location.href: ", window.location.href)
  let brandlogo = window.location.href.includes('tuppconnect') ? '/logo/tupp_connect_full.png' : '/logo/megsapp-logo.png';
  if (window.location.href.includes('localhost')) {
    console.log("window.location.href.includes('localhost'): ", window.location.href)
  }
  return (
    <StyledRoot
      sx={{
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' }
      }}
    >
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection
        sx={{
          flex: { xs: 1, md: 0.7 }, 
          marginBottom: { xs: 2, md: 0 }, 
        }}
      >
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || brandlogo}
          sx={{ maxWidth: 720, width: '100%' }} 
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent
        sx={{
          flex: { xs: 1, md: 0.3 }, 
          mx: { xs: 2, md: 5 }, 
          width: { xs: 'auto', md: '75%' }, 
        }}
      >
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
