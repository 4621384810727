import { Link as RouterLink } from "react-router-dom";
// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from "@mui/material";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// routes
import { PATH_AUTH } from "../../routes/paths";
// layouts
import LoginLayout from "../../layouts/login";
//
import AdminAuthLoginForm from "./AdminAuthLoginForm";

// ----------------------------------------------------------------------

export default function LoginAdmin() {
  const { method } = useAuthContext();

  const brandname = window.location.href.includes("tuppconnect") ? "Tupp Connect" : "megsApp";

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">{`Sign in to ${brandname} portal`}</Typography>
      </Stack>

      <AdminAuthLoginForm />

      <Stack spacing={2} sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Link component={RouterLink} to={`${PATH_AUTH.forgotPassword}`} sx={{ marginTop: "16px !important", mx: "auto", display: "block" }}>
          Forgot password?
        </Link>
      </Stack>
    </LoginLayout>
  );
}
