import { Card, Divider, Grid, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types"; // Import PropTypes
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Label from "src/components/label";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import VideoCard from "./VideoCard";
export default function AgentVideosList({ videoCategories, videosList, isLoading }) {
  // Add prop validation
  AgentVideosList.propTypes = {
    videoCategories: PropTypes.array.isRequired,
    videosList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [error, setError] = useState(null);
  const [tabStatus, setTabStatus] = useState("All");

  const getLengthByStatus = (status) => (status !== "All" ? videosData.filter((item) => item.category_name === status).length : videosData.length);

  const handleTabStatus = (event, newValue) => {
    setTabStatus(newValue);
  };

  useEffect(() => {
    setVideos(videosList);
    setVideosData(videosList);
  }, [videosList]);

  useEffect(() => {
    if (tabStatus !== "All") {
      const filteredVideos = videosData?.filter((video) => {
        const isStatusMatch = tabStatus === "All" || tabStatus === video?.category_name;

        return isStatusMatch;
      });

      setVideos([...filteredVideos]);
    } else {
      setVideos(videosList);
    }
  }, [tabStatus]);

  if (isLoading) return <LoadingScreen />;

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Tabs
        value={tabStatus}
        onChange={handleTabStatus}
        sx={{
          px: 2,
          bgcolor: "background.neutral",
          borderRadius: "5px",
        }}
      >
        {videoCategories.map((tab) => (
          <Tab
            key={tab.name}
            value={tab.name}
            label={tab.name}
            icon={
              <Label color="primary" sx={{ mr: 1 }}>
                {getLengthByStatus(tab.name)}
              </Label>
            }
          />
        ))}
      </Tabs>
      <Divider
        sx={{
          borderRadius: "10px",
        }}
      />

      <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{ mt: 1 }}>
        {videos.map((video) => (
          <Grid item xs={4} key={video.id}>
            <VideoCard videoDetails={video} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
