import PropTypes from "prop-types";
// @mui
import { IconButton, Link, TableCell, TableRow, Typography } from "@mui/material";
// import check and cross icons from material icons
import { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { deleteVideoDetails } from "src/api/megs-api-api";
import { ConfirmationDialog } from "src/components/dialog/ConfirmationDialog";
import Iconify from "src/components/iconify";
import Label from "src/components/label";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

// ----------------------------------------------------------------------

VideoTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function VideoTableRow({ row, selected, onViewRow, handleReloadVideoData }) {
  const { category_id, category_name, category_type, brand, created_at, description, distributor_i_customer, id, link, name, thumbnail, updated_at } =
    row;
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    show: false,
    id: null,
    role: null,
  });

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  const handleVideoDelete = (id) => {
    setShowConfirmPopup({
      show: true,
      id: id,
    });
  };

  const handleVideosEdit = (id) => {
    navigate(PATH_DASHBOARD.trainingVideos.view(id, category_type));
  };

  const handleDeleteVideoData = async (id) => {
    const result = await deleteVideoDetails(id);
    if (result.status_code === 1001) {
      navigate(PATH_DASHBOARD.trainingVideos.list);
      handleReloadVideoData();
      setShowConfirmPopup({
        show: false,
        id: null,
      });
      enqueueSnackbar("Video deleted successfully", { variant: "success" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    } else {
      enqueueSnackbar(result?.data?.message, { variant: "error" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    }
  };

  return (
    <>
      {showConfirmPopup.show && (
        <ConfirmationDialog
          description={"Are you sure you want to delete this video?"}
          showConfirmPopup={showConfirmPopup.show}
          onClose={() => setShowConfirmPopup({ show: false, id: null })}
          onConfirm={() => {
            handleDeleteVideoData(showConfirmPopup.id);
          }}
        />
      )}
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} underline="none">
        <TableCell>
          <Typography variant="body2">
            {name}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2">
            {description}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2">
            {brand}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Label variant="soft" color={"warning"}>
            <Typography variant="subtitle2">
              {category_name}
            </Typography>
          </Label>
        </TableCell>
        <TableCell align="left" >
          <IconButton
            onClick={() => {
              handleVideosEdit(id);
            }}
          >
            <Iconify icon="eva:edit-fill" />
          </IconButton>
          <IconButton
            onClick={() => {
              handleVideoDelete(id);
            }}
          >
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </TableCell>
      </Link>
    </>
  );
}
